import React from 'react';
import { TableCell, IconButton, TableCellProps, Typography, Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import styles from './SortableTableCell.module.css';
import { SortConfig } from '../../../hooks/useTableUtils';
import { SortDirection } from '../../../enums';

interface SortableTableCellProps extends TableCellProps {
  columnKey: string;
  hoveredColumn: string | null;
  sortConfig: SortConfig | null;
  setHoveredColumn: (column: string | null) => void;
  requestSort: (key: string) => void;
  children: React.ReactNode;
  tableCellContentStyle?: React.CSSProperties;
}

const SortableTableCell: React.FC<SortableTableCellProps> = ({
  columnKey,
  hoveredColumn,
  sortConfig,
  setHoveredColumn,
  requestSort,
  children,
  tableCellContentStyle,
  ...rest
}) => {
  const isHoveredOrSorted = hoveredColumn === columnKey || sortConfig?.key === columnKey;

  const sortIcon =
    sortConfig?.direction === SortDirection.DESC ? (
      <ArrowDownwardIcon fontSize="small" />
    ) : (
      <ArrowUpwardIcon fontSize="small" />
    );

  return (
    <TableCell
      onMouseEnter={() => setHoveredColumn(columnKey)}
      onMouseLeave={() => setHoveredColumn(null)}
      onClick={() => requestSort(columnKey)}
      className={styles.tableCell}
      {...rest}
    >
      <Box style={tableCellContentStyle} className={styles.tableCellContent}>
        <Typography variant="body2" fontWeight="500">
          {children}
        </Typography>
        <Box className={styles.iconButtonContainer}>
          {isHoveredOrSorted && <IconButton>{sortIcon}</IconButton>}
        </Box>
      </Box>
    </TableCell>
  );
};

export default SortableTableCell;
