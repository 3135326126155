import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAuth } from '../../services/auth';
import styles from './PrivateLayout.module.css';
import AppHeader from '../AppHeader/AppHeader';
import HeaderTabs from '../HeaderTabs/HeaderTabs';
import { useUserSettingsStore } from '../../stores';
import { CircularProgress } from '@mui/material';
import { useLoadingOnValueChange } from '../../hooks';

const PrivateLayout = () => {
  const { isAuthenticated } = useAuth();
  const selectedProjectId = useUserSettingsStore((store) => store.selectedProjectId);
  const loading = useLoadingOnValueChange(selectedProjectId);

  return isAuthenticated ? (
    <Box className={styles.container}>
      <AppHeader />
      <HeaderTabs />
      <Box component="main" className={styles.main}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
            <CircularProgress />
          </Box>
        ) : (
          <Outlet key={selectedProjectId} />
        )}
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateLayout;
