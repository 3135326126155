import moment from 'moment';
import { User } from '../../../../services/api/user-system/types';

export const formatDate = (date: string): string => moment(date).format('DD MMM YYYY, HH:mm:ss');

export const defaultRowsPerPage = 25;

export const rowsPerPageOptions = [5, 10, 25, 50];

export const filterUsersByKeyword = (user: User, keyword: string) =>
  user.email.toLowerCase().includes(keyword.toLowerCase());
