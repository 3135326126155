import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, FormControl } from '@mui/material';

import styles from './CreateUserModal.module.css';

interface CreateUserModalProps {
  open: boolean;
  onSubmit: (email: string) => void;
  onClose: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({ open, onSubmit, onClose }) => {
  const [email, setEmail] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onSubmit(email);
    setEmail('');
  };

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={open}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <FormControl onSubmit={handleSubmit} className={styles.form} component="form">
          <TextField
            name="email"
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={handleChange}
            required
          />

          <Button variant="contained" color="primary" type="submit" className={styles.submitButton}>
            Submit
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
