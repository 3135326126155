import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import styles from './TagIndicator.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface TagIndicatorProps {
  label: string;
  backgroundColor: string;
  dotColor: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCloseClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}

const TagIndicator: React.FC<TagIndicatorProps> = ({
  label,
  backgroundColor,
  dotColor,
  onClick,
  onCloseClick,
  style,
}) => {
  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onCloseClick?.(event);
  };

  return (
    <Box
      style={style}
      className={styles.statusChip}
      onClick={onClick}
      sx={{
        backgroundColor,
      }}
    >
      <Box className={styles.contentWrapper}>
        <Box className={styles.dot} style={{ backgroundColor: dotColor }} />
        <Typography variant="body2">{label}</Typography>
      </Box>

      {onCloseClick && (
        <IconButton className={styles.closeButton} onClick={handleCloseClick}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      )}
    </Box>
  );
};

export default TagIndicator;
