import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      ':root': {
        '--mui-palette-primary-main': theme.palette.primary.main,
      },
    },
  },
};

export default theme;
