import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  DialogActions,
} from '@mui/material';
import styles from './CreateProjectModal.module.css';
import { useAuth } from '../../../services/auth';
import { useRunSearch } from '../../../services/api/search/hooks';
import { useUserSettingsStore } from '../../../stores';
import { Project } from '../../../services/api/workspace/types';
import { useCreateProject } from '../../../services/api/workspace/projects/hooks';
import { SearchResponse } from '../../../services/api/search/types';

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  isFirstProjectCreation?: boolean;
  projectsLength: number;
}

const totalSteps = 2;

const getTitleByStep = (step: number, isFirstProjectCreation: boolean) => {
  const titleByStep: { [key: number]: string } = {
    1: isFirstProjectCreation ? 'Name your first event.' : 'Name your event.',
    2: 'Begin your research.',
  };

  return titleByStep[step];
};

const getDescriptionByStep = (step: number) => {
  const descriptionByStep: { [key: number]: string } = {
    1: 'You’re just a few steps away from building a powerful agenda. Let’s start by naming your event:',
    2: "Enter the first theme or topic you'd like to explore for this event:",
  };

  return descriptionByStep[step];
};

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  isOpen,
  onClose,
  isFirstProjectCreation = false,
  projectsLength,
}) => {
  const { setSelectedProjectId, setSelectedSearchId } = useUserSettingsStore();
  const { currentUser } = useAuth();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [newProjectName, setNewProjectName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { mutate: createProject } = useCreateProject();
  const { mutate: runSearch } = useRunSearch();

  const projectNameFilled = newProjectName.trim().length;

  const resetState = () => {
    setCurrentStep(1);
    setNewProjectName('');
    setSearchQuery('');
  };

  const handleBack = () => setCurrentStep((prevStep) => prevStep - 1);
  const handleNext = () => {
    if (currentStep === totalSteps) {
      if (!searchQuery.trim().length) return;

      createProject(
        { projectName: newProjectName, userId: currentUser?.id || '' },
        {
          onSuccess: (project: Project) => {
            setSelectedProjectId(project.id);
            runSearch(searchQuery, {
              onSuccess: (data: SearchResponse) => {
                setSelectedSearchId(data.search_id);
              },
            });
            onClose();
            resetState();
          },
        },
      );
      return;
    }
    if (projectNameFilled) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleClose = () => {
    if (!projectsLength) {
      createProject(
        { projectName: 'Untitled event', userId: currentUser?.id || '' },
        {
          onSuccess: (project: Project) => {
            setSelectedProjectId(project.id);
            onClose();
            resetState();
          },
        },
      );
    }
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <DialogTitle className={styles.title}>
        {isFirstProjectCreation && (
          <Typography mt={2} mb={1}>
            Welcome to Event Producer Pro
          </Typography>
        )}
        {getTitleByStep(currentStep, isFirstProjectCreation)}
      </DialogTitle>
      <DialogContent>
        <Typography>{getDescriptionByStep(currentStep)}</Typography>
        {currentStep === 1 ? (
          <TextField
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
            placeholder="Event name"
            variant="standard"
            size="small"
            sx={{ width: '100%' }}
            InputProps={{
              inputProps: { style: { padding: '22px 16px 16px 0' } },
            }}
          />
        ) : (
          <TextField
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="eg. ‘artificial intelligence’"
            variant="standard"
            size="small"
            sx={{ width: '100%' }}
            InputProps={{
              inputProps: { style: { padding: '22px 16px 16px 0' } },
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 24px 24px 24px' }}
      >
        <Typography className={styles.step}>
          Step {currentStep} of {totalSteps}
        </Typography>
        <Box>
          <Button onClick={handleClose}>Close</Button>
          {currentStep > 1 && <Button onClick={handleBack}>Back</Button>}
          <Button onClick={handleNext} variant="contained" disabled={!projectNameFilled}>
            Next
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectModal;
