import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import QueueIcon from '@mui/icons-material/Queue';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import { useQueueLength } from '../../services/api/search/hooks';
import styles from './AccountMenu.module.css';
import { useAuth } from '../../services/auth';
import { Link } from 'react-router-dom';

const AccountMenu = () => {
  const { signOut, getContextUser, currentUser } = useAuth();
  const username = getContextUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: queueLength, refetch: refetchQueueLength } = useQueueLength();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!open) {
      refetchQueueLength();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const avatarLetter = username ? username[0].toUpperCase() : '?';

  return (
    <React.Fragment>
      <Box className={styles.accountMenu}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={toggleMenu}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar className={styles.avatar}>{avatarLetter}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className={styles.user}>
          <Avatar className={styles.avatar}>{avatarLetter}</Avatar>
          {username}
        </MenuItem>
        <MenuItem className={styles.queueLengthContainer}>
          <QueueIcon color="disabled" className={styles.icon} />
          <Typography>Queue Length: {queueLength}</Typography>
        </MenuItem>
        <Divider />

        {currentUser?.is_superuser && (
          <MenuItem component={Link} to="/backoffice" target="_blank">
            <ListItemIcon>
              <GroupsIcon fontSize="small" />
            </ListItemIcon>
            Backoffice
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;
