import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './services/auth';
import { ConfirmProvider } from 'material-ui-confirm';
import theme from './theme';

import { ThemeProvider, CssBaseline } from '@mui/material';
import { publicRoutes } from './routes';
import { PrivateLayout } from './layouts';
import PrivateRoutes from './components/PrivateRoutes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              {publicRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
              <Route element={<PrivateLayout />}>
                <Route path="/*" element={<PrivateRoutes />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
