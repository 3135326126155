import React from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import PriorityIndicator from './PriorityIndicator';
import { Priorities } from '../../enums';

interface SelectPriorityProps {
  onChange: (event: SelectChangeEvent<string>) => void;
  value: string;
}

const SelectPriority: React.FC<SelectPriorityProps> = ({ onChange, value }) => {
  return (
    <FormControl>
      <Select
        value={value}
        label="priority"
        onChange={onChange}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
      >
        <MenuItem value={Priorities.HIGH}>
          <PriorityIndicator type={Priorities.HIGH} />
        </MenuItem>
        <MenuItem value={Priorities.MEDIUM}>
          <PriorityIndicator type={Priorities.MEDIUM} />
        </MenuItem>
        <MenuItem value={Priorities.LOW}>
          <PriorityIndicator type={Priorities.LOW} />
        </MenuItem>
        <MenuItem value={Priorities.NOT_SET}>
          <PriorityIndicator type={Priorities.NOT_SET} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectPriority;
