enum Status {
  ERROR = 'error',
  COMPLETE = 'complete',
  NO_EXTRACT = 'no_extract',
  LOW_GRADE = 'lowgrade',
  HOLD = 'hold',
  PENDING = 'pending',
  PROCESSING = 'processing',
  QUEUED = 'queued',
}

export default Status;
