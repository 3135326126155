import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Folder } from '../../services/api/workspace/types';
import AddFolderModal from '../../components/workspace/AddFolderModal/AddFolderModal';
import { useToggle } from '../../hooks';
import styles from './Workspace.module.css';
import FolderCard from '../../components/workspace/FolderCard/FolderCard';
import WorkspaceTable from '../../components/workspace/WorkspaceTable/WorkspaceTable';
import { useUserSettingsStore } from '../../stores';
import { useCreateFolder, useFetchFolders } from '../../services/api/workspace/folders/hooks';

const Workspace = () => {
  const { setSelectedFolderId, selectedFolderId } = useUserSettingsStore();
  const { data: folders } = useFetchFolders({});

  const { mutate: createFolder } = useCreateFolder();

  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);

  const [addFolderModalOpen, toggleAddFolderModal] = useToggle(false);

  const pickFolder = useCallback(
    (folder: Folder) => {
      setSelectedFolder(folder);
      setSelectedFolderId(folder.id);
    },
    [setSelectedFolderId],
  );

  useEffect(() => {
    if (folders.length) {
      const folderToSelect = selectedFolderId
        ? folders.find((folder) => folder.id === selectedFolderId) || folders[0]
        : folders[0];

      pickFolder(folderToSelect);
    }
  }, [folders, selectedFolderId, setSelectedFolderId, pickFolder]);

  return (
    <Box className={styles.container}>
      <Box className={styles.foldersRow}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={toggleAddFolderModal}
          className={styles.addFolderButton}
        >
          Add theme
        </Button>
        <Box className={styles.folders}>
          {folders.map((folder) => (
            <FolderCard
              key={folder.id}
              folder={folder}
              selectedFolderId={selectedFolder?.id}
              onCardClick={() => pickFolder(folder)}
            />
          ))}
        </Box>
      </Box>
      {selectedFolder && <WorkspaceTable />}
      <AddFolderModal
        isOpen={addFolderModalOpen}
        onSubmit={createFolder}
        onCancel={toggleAddFolderModal}
      />
    </Box>
  );
};

export default Workspace;
