import { useMutation, useQueryClient } from '@tanstack/react-query';
import { managePersonFolders } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useManagePersonFolders = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (payload: {
      personExtractId: string;
      foldersIdsToAdd: string[];
      foldersIdsToRemove: string[];
    }) => managePersonFolders(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fullSearch'] });
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      showSnackbar(Messages.SUCCESS.FOLDERS_SELECTIONS_UPDATED, SnackbarType.SUCCESS);
    },
  });
};

export default useManagePersonFolders;
