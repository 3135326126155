import apiClient from '../../apiClient';
import { BASE_API_URL, headers } from '../../config';
import { PaginationParams } from '../../types';
import { Folder } from '../types';

export const createFolder = (folderName: string): Promise<Folder> => {
  return apiClient
    .post(`${BASE_API_URL}/folders`, { name: folderName })
    .then((response) => response.data);
};

export const fetchFolders = (payload: PaginationParams): Promise<Folder[]> => {
  return apiClient
    .get(`${BASE_API_URL}/folders`, { params: { ...payload, size: 100 }, headers })
    .then((response) => response.data);
};

export const deleteFolder = (folderId: string) => {
  return apiClient.delete(`${BASE_API_URL}/folders/${folderId}`).then((response) => response.data);
};

export const updateFolder = (folderId: string, payload: { name?: string; order?: number }) => {
  return apiClient
    .put(`${BASE_API_URL}/folders/${folderId}`, payload)
    .then((response) => response.data);
};
