import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProject } from '../requests';

const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectId: string) => deleteProject(projectId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });
};

export default useDeleteProject;
