import { useMutation, useQueryClient } from '@tanstack/react-query';
import { runSearch } from '../requests';
import { useSnackbar } from '../../../snackbar';
import { SnackbarType } from '../../../../enums';
import { Messages } from '../../../../constants';

const useRunSearch = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (searchTerm: string) => runSearch(searchTerm),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searches'] });
      showSnackbar(Messages.SUCCESS.SEARCH_STARTED, SnackbarType.SUCCESS);
    },
  });
};

export default useRunSearch;
