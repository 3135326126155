import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTag } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useCreateTag = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (payload: { name: string; color: string }) => createTag(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
      queryClient.invalidateQueries({ queryKey: ['peopl'] });

      showSnackbar(Messages.SUCCESS.TAG_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateTag;
