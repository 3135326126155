import { useQuery } from '@tanstack/react-query';
import { generatePassword } from '../../requests';

const useGeneratePassword = (options = {}) => {
  return useQuery({
    queryKey: ['password'],
    queryFn: () => generatePassword(),
    ...options,
  });
};

export default useGeneratePassword;
