import { useQuery } from '@tanstack/react-query';
import { fetchTopicDetails } from '../requests';

const useFetchTopicDetails = (folderId: string, topicExtractId: string) => {
  return useQuery({
    queryKey: ['topic_details'],
    queryFn: () => fetchTopicDetails(folderId, topicExtractId),
    initialData: null,
  });
};

export default useFetchTopicDetails;
