import { useMutation, useQueryClient } from '@tanstack/react-query';
import { movePersonToAnotherFolder } from '../requests';

const useMovePersonToAnotherFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { folderId: string; extractId: string; folderIdDestination: string }) =>
      movePersonToAnotherFolder(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
      queryClient.invalidateQueries({ queryKey: ['folders'] });
    },
  });
};

export default useMovePersonToAnotherFolder;
