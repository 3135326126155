import { useQuery } from '@tanstack/react-query';
import { fetchPeopleNotes } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchPeopleNotes = (payload: PaginationParams & { people_extract_id: string }) => {
  return useQuery({
    queryKey: ['notes', payload.people_extract_id],
    queryFn: () => fetchPeopleNotes(payload),
    initialData: [],
  });
};

export default useFetchPeopleNotes;
