import React, { useState } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import TrendingTopics from './TrendingTopics/TrendingTopics';
import { TopicResult, PersonResult } from '../../services/api/search/types';
import TrendingPeople from './TrendingPeople/TrendingPeople';

import { SearchDetails } from '../../services/api/search/types';
import { Status } from '../../enums';
import SpinningIcon from '../shared/SpinningIcon/SpinningIcon';

interface SearchResultsProps {
  searchDetails: SearchDetails;
}

const SearchResults: React.FC<SearchResultsProps> = ({ searchDetails }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabLabel = (title: string) => (
    <>
      {title}
      {searchDetails.extraction_status === Status.PROCESSING && <SpinningIcon fontSize="small" />}
    </>
  );

  return (
    <Box sx={{ width: '100%', p: 1, boxSizing: 'border-box' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="search results tabs">
          <Tab label={tabLabel('Topics')} />
          <Tab label={tabLabel('People')} />
        </Tabs>
      </Box>
      <TabPanel<TopicResult> value={value} index={0} content={searchDetails.topic_results} />
      <TabPanel<PersonResult> value={value} index={1} content={searchDetails.people_results} />
    </Box>
  );
};

interface TabPanelProps<T> {
  value: number;
  index: number;
  content: T[];
}

const TabPanel = <T,>({ value, index, content, ...other }: TabPanelProps<T>) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2, padding: 2 }}>
          {index === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TrendingTopics topics={content as TopicResult[]} />
              </Grid>
            </Grid>
          )}
          {index === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TrendingPeople people={content as PersonResult[]} />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
};

export default SearchResults;
