import React, { useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface PasswordInputProps {
  name: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  helperText?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  onFocus,
  onBlur,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <TextField
      name={name}
      label={label}
      variant="outlined"
      fullWidth
      margin="normal"
      type={passwordVisible ? 'text' : 'password'}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={togglePasswordVisibility}>
              {passwordVisible ? (
                <VisibilityOffIcon color="disabled" />
              ) : (
                <VisibilityIcon color="disabled" />
              )}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
