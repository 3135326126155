import * as XLSX from 'xlsx';

export const noop = () => {};

export const getDomainName = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const exportToExcel = (filename: string, headers: string[], rows: string[][]) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateLinkToPersonLinkedin = (name: string) => {
  return `https://www.linkedin.com/search/results/all/?keywords=${name}&origin=GLOBAL_SEARCH_HEADER`;
};

export const exportToCsv = (filename: string, headers: string[], rows: string[][]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  csvContent += `${headers.join(',')}\n`;

  rows.forEach((row) => {
    const rowContent = row.map((cell) => `"${cell.replace(/"/g, '""')}"`).join(',');
    csvContent += `${rowContent}\n`;
  });
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const pickMatchScoreLabelColor = (matchScore: number) => {
  if (matchScore >= 75) return 'success.main';
  if (matchScore >= 50) return '#ffcf33';
  if (matchScore >= 25) return 'warning.main';

  return 'error.main';
};

export const truncateText = (text: string, maxLength = 50) => {
  return text.length <= maxLength ? text : text.slice(0, text.lastIndexOf(' ', maxLength)) + '...';
};
