import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import styles from './HeaderTabs.module.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserSettingsStore } from '../../stores';
import { Project } from '../../services/api/workspace/types';
import SelectProjectMenu from '../../components/shared/SelectProjectMenu/SelectProjectMenu';
import CreateProjectModal from '../../components/shared/CreateProjectModal/CreateProjectModal';
import { useToggle } from '../../hooks';
import { useFetchProjects } from '../../services/api/workspace/projects/hooks';
import { truncateText } from '../../services/utils';

const tabs = [
  { label: 'RESEARCH', value: 0, to: '/dashboard' },
  { label: 'WORKSPACE', value: 1, to: '/workspace' },
  { label: 'AGENDA', value: 2, to: '/agenda' },
];

const HeaderTabs = () => {
  const [createProjectModalOpen, toggleCreateProjectModalOpen] = useToggle(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const { selectedProjectId, setSelectedProjectId, setSelectedSearchId } = useUserSettingsStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const { data: projects, isFetched } = useFetchProjects({});

  useEffect(() => {
    if (projects.length) {
      setSelectedProject(
        projects.find((project) => project.id === selectedProjectId) || projects[0] || null,
      );
    } else {
      if (isFetched) {
        handleProjectMenuClose();
      }
    }
  }, [projects, selectedProjectId, isFetched]);

  const currentTabValue = tabs.findIndex((tab) => tab.to === location.pathname);

  const handleProjectMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget as HTMLDivElement);
  };

  const handleProjectMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabClick = (path: string) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const handleProjectClick = useCallback(
    (projectId: string) => {
      setSelectedProjectId(projectId);
      setSelectedSearchId('');
      handleProjectMenuClose();
    },
    [setSelectedProjectId, setSelectedSearchId],
  );

  const handleAddProjectClick = () => {
    toggleCreateProjectModalOpen();
    handleProjectMenuClose();
  };

  return (
    <Box className={styles.root}>
      <Box onClick={handleProjectMenuClick} className={styles.header}>
        <Typography sx={{ color: '#616161' }} fontSize={34} mr={2}>
          {truncateText(selectedProject?.name || '', 40)}
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Tabs value={currentTabValue} classes={{ indicator: styles.indicator }}>
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            label={tab.label}
            className={`${styles.tab} ${tab.value === currentTabValue ? styles.selectedTab : ''}`}
            onClick={() => handleTabClick(tab.to)}
          />
        ))}
      </Tabs>
      <SelectProjectMenu
        selectedProjectId={selectedProjectId}
        anchorEl={anchorEl}
        onClose={handleProjectMenuClose}
        projects={projects}
        onAddProjectClick={handleAddProjectClick}
        onMenuItemClick={handleProjectClick}
      />
      <CreateProjectModal
        projectsLength={projects.length}
        isOpen={createProjectModalOpen}
        onClose={toggleCreateProjectModalOpen}
      />
    </Box>
  );
};

export default HeaderTabs;
