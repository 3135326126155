import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTopicNote } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useCreateTopicNote = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ topic_extract_id, text }: { topic_extract_id: string; text: string }) =>
      createTopicNote(topic_extract_id, text),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
      showSnackbar(Messages.SUCCESS.NOTE_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateTopicNote;
