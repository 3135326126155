import React from 'react';
import { Typography, Chip, Box, Tooltip } from '@mui/material';
import { SearchDetails } from '../../../services/api/search/types';
import { getStatusChipProps } from './SearchSummary.utils';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchStatusCard from '../SearchStatusCard/SearchStatusCard';
import { useToggle } from '../../../hooks';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SearchSummary.module.css';

interface SearchSummaryProps {
  searchDetails: SearchDetails;
}

const SearchSummary: React.FC<SearchSummaryProps> = ({ searchDetails }) => {
  const [showDetails, toggleShowDetails] = useToggle(false);

  const title =
    searchDetails.search_term.charAt(0).toUpperCase() + searchDetails.search_term.slice(1);

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title} variant="h4">
          {title}
        </Typography>
        <Chip
          className={styles.status}
          {...getStatusChipProps(searchDetails.extraction_status, 'Extraction')}
        />
        <Tooltip placement="top" title="Show more search info">
          <InfoOutlinedIcon color="action" sx={{ cursor: 'pointer' }} onClick={toggleShowDetails} />
        </Tooltip>
      </Box>

      {showDetails && (
        <Box className={styles.details}>
          <CloseIcon
            className={styles.closeIcon}
            onClick={toggleShowDetails}
            color="action"
            fontSize="medium"
          />
          <SearchStatusCard searchDetails={searchDetails} />
        </Box>
      )}
    </Box>
  );
};

export default SearchSummary;
