import React from 'react';
import { Menu, Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Project } from '../../../services/api/workspace/types';
import styles from './SelectProjectMenu.module.css';
import SelectProjectMenuItem from '../SelectProjectMenuItem/SelectProjectMenuItem';

interface SelectProjectMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  projects: Project[];
  selectedProjectId: string;
  onAddProjectClick: () => void;
  onMenuItemClick: (projectId: string) => void;
}

const SelectProjectMenu: React.FC<SelectProjectMenuProps> = ({
  anchorEl,
  onClose,
  projects,
  selectedProjectId,
  onAddProjectClick,
  onMenuItemClick,
}) => {
  return (
    <Menu
      slotProps={{
        paper: {
          sx: { width: 400 },
        },
      }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {projects.map((project) => (
        <SelectProjectMenuItem
          key={project.id}
          selectedProjectId={selectedProjectId}
          project={project}
          onMenuItemClick={onMenuItemClick}
        />
      ))}

      <Box className={styles.buttonContainer}>
        <Button
          className={styles.button}
          onClick={onAddProjectClick}
          variant="outlined"
          startIcon={<AddIcon />}
          color="primary"
        >
          <Typography variant="body2">ADD EVENT</Typography>
        </Button>
      </Box>
    </Menu>
  );
};

export default SelectProjectMenu;
