import { useQuery } from '@tanstack/react-query';
import { PaginationParams } from '../../../types';
import { fetchPersonNotesAttachments } from '../requests';

const useFetchPersonNotesAttachments = (personExtractId: string, payload: PaginationParams) => {
  return useQuery({
    queryKey: ['notes_attachments', personExtractId],
    queryFn: () => fetchPersonNotesAttachments(personExtractId, payload),
    initialData: [],
  });
};

export default useFetchPersonNotesAttachments;
