import axios from 'axios';
import { BASE_API_URL, headers } from './config';

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers,
});

export const setAuthToken = (token: string) => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setProjectId = (projectId: string) => {
  apiClient.defaults.headers.common['X-Project-ID'] = projectId;
};

export default apiClient;
