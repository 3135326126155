import { useQuery } from '@tanstack/react-query';
import { fetchTopicNotesAttachments } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchTopicNotesAttachments = (topicExtractId: string, payload: PaginationParams) => {
  return useQuery({
    queryKey: ['notes_attachments', topicExtractId],
    queryFn: () => fetchTopicNotesAttachments(topicExtractId, payload),
    initialData: [],
  });
};

export default useFetchTopicNotesAttachments;
