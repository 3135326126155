import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTag } from '../requests';

const useDeleteTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tagId: string) => deleteTag(tagId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });
};

export default useDeleteTag;
