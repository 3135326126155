import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '../services/auth';
import { privateRoutes } from '../routes';

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  const availablePrivateRoutes = useMemo(() => {
    return privateRoutes.filter((route) => {
      if (route.superUserOnly) {
        return Boolean(currentUser?.is_superuser);
      }
      return true;
    });
  }, [currentUser]);

  return (
    <Routes>
      {availablePrivateRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default PrivateRoutes;
