import { useQuery } from '@tanstack/react-query';
import { fetchPersonActivities } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchPersonActivities = (
  folderId: string,
  personExtractId: string,
  payload: PaginationParams,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ['person_activities'],
    queryFn: () => fetchPersonActivities(folderId, personExtractId, payload),
    initialData: { data: [], totalCount: '0' },
    enabled,
  });
};

export default useFetchPersonActivities;
