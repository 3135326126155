import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Button,
  FormControl,
} from '@mui/material';

import styles from './UpdateUserModal.module.css';
import { UserUpdateFormFields } from '../../../../services/api/user-system/types';

interface UpdateUserModalProps {
  open: boolean;
  onSubmit: (formData: UserUpdateFormFields) => void;
  defaultValues: UserUpdateFormFields;
  onClose: () => void;
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
  open,
  onSubmit,
  defaultValues,
  onClose,
}) => {
  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    setFormData(defaultValues);
  }, [defaultValues]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onSubmit(formData);
  };

  return (
    <Dialog onClose={onClose} maxWidth="xs" fullWidth open={open}>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <FormControl onSubmit={handleSubmit} className={styles.form} component="form">
          <FormControlLabel
            control={
              <Checkbox name="is_active" checked={formData.is_active} onChange={handleChange} />
            }
            label="Active"
          />

          <Button variant="contained" color="primary" type="submit" className={styles.submitButton}>
            Submit
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUserModal;
