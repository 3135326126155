import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from '../../../../snackbar';
import { resendUserVerification } from '../../requests';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useResendUserVerification = () => {
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (userId: string) => resendUserVerification(userId),
    onSuccess: () => {
      showSnackbar(Messages.SUCCESS.EMAIL_RESENT, SnackbarType.SUCCESS);
    },
  });
};

export default useResendUserVerification;
