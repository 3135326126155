import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNoteAttachment } from '../requests';

const useDeleteNoteAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (noteAttachmentId: string) => deleteNoteAttachment(noteAttachmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes_attachments'] });
    },
  });
};

export default useDeleteNoteAttachment;
