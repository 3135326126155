import React from 'react';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

interface EditActionsProps {
  onSave: (event: React.MouseEvent<HTMLElement>) => void;
  onCancel: (event: React.MouseEvent<HTMLElement>) => void;
  color?: string;
  containerClassName?: string;
}

const defaultIconColor = '#9e9e9e';

const EditActions: React.FC<EditActionsProps> = ({ onSave, onCancel, containerClassName }) => {
  return (
    <Box className={containerClassName}>
      <IconButton onClick={onSave}>
        <CheckIcon sx={{ color: defaultIconColor }} />
      </IconButton>
      <IconButton onClick={onCancel}>
        <CloseIcon sx={{ color: defaultIconColor }} />
      </IconButton>
    </Box>
  );
};

export default EditActions;
