import React from 'react';
import ReactCodeInput from 'react-code-input';

export const codeInputStyle = {
  margin: '8px',
  width: '60px',
  borderRadius: '8px',
  fontSize: '24px',
  height: '60px',
  color: '#333',
  border: '2px solid #ccc',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  outline: 'none',
};

export const codeInputStyleInvalid = { ...codeInputStyle, border: '2px solid #d32f2f' };

interface CodeInputProps {
  onChange: (code: string) => void;
  isValid?: boolean;
  codeLength?: number;
}

const CodeInput: React.FC<CodeInputProps> = ({ onChange, isValid, codeLength }) => {
  return (
    <ReactCodeInput
      name="Code"
      inputMode="email"
      type="number"
      inputStyle={{
        ...codeInputStyle,
        textAlign: 'center',
      }}
      inputStyleInvalid={{ ...codeInputStyleInvalid, textAlign: 'center' }}
      fields={codeLength}
      onChange={onChange}
      isValid={isValid}
    />
  );
};

export default CodeInput;
