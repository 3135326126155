import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTagFromExtract } from '../requests';

const useDeleteTagFromExtract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tagExtractId: string) => deleteTagFromExtract(tagExtractId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
  });
};

export default useDeleteTagFromExtract;
