import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './UserVerification.module.css';
import { useVerifyUser, useSetUserPassword } from '../../services/api/user-system/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../services/auth';
import PasswordForm from '../../components/user-system/PasswordForm/PasswordForm';
import CodeInput from '../../components/user-system/CodeInput/CodeInput';
import AppHeader from '../../layouts/AppHeader/AppHeader';

const codeLength = 6;

const UserVerification = () => {
  const auth = useAuth();
  const { subId } = useParams();
  const navigate = useNavigate();

  const [tempToken, setTempToken] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(true);

  const { mutate: verifyUser } = useVerifyUser();
  const { mutate: setUserPassword } = useSetUserPassword();

  const handleCodeChange = (code: string) => {
    if (code.length === codeLength) {
      verifyUser(
        { subId: subId || '', confirmationCode: code },
        {
          onSuccess: (response) => {
            setTempToken(response.headers['authorization']);
            setShowPassword(true);
          },
          onError() {
            setIsCodeValid(false);
          },
        },
      );
    }
  };

  const handleSubmit = (password: string) => {
    setUserPassword(
      { subId: subId || '', password, tempToken },
      {
        onSuccess: (data) => {
          auth.signIn(data.email, password).then(() => navigate('/dashboard'));
        },
      },
    );
  };

  return (
    <Box className={styles.container}>
      <AppHeader showAccountMenu={false} />
      {showPassword ? (
        <Box className={styles.content}>
          <Typography className={styles.passwordLabel} variant="h6" gutterBottom>
            Enter your new password
          </Typography>
          <PasswordForm handleSubmit={handleSubmit} />
        </Box>
      ) : (
        <Box className={styles.content}>
          <Typography className={styles.codeLabel} variant="h6">
            Enter code sent to your email address
          </Typography>
          <CodeInput codeLength={codeLength} onChange={handleCodeChange} isValid={isCodeValid} />
        </Box>
      )}
    </Box>
  );
};

export default UserVerification;
