import React from 'react';
import { useState, useMemo } from 'react';
import { SortDirection } from '../enums';

export interface SortConfig {
  key: string;
  direction: SortDirection | null;
}

const useTableUtils = <T,>(
  data: T[],
  defaultRowsPerPage = 10,
  filterFunction?: (item: T, keyword: string) => boolean,
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [filterKeyword, setFilterKeyword] = useState<string>('');
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  const filteredData = useMemo(() => {
    if (!filterKeyword || !filterFunction) return data;
    return data.filter((item) => filterFunction(item, filterKeyword));
  }, [data, filterKeyword, filterFunction]);

  const sortedData = useMemo(() => {
    if (!sortConfig) return filteredData;

    const { key, direction } = sortConfig;
    return [...filteredData].sort((a: any, b: any) => {
      if (a[key] > b[key]) {
        return direction === SortDirection.ASC ? 1 : -1;
      }
      if (a[key] < b[key]) {
        return direction === SortDirection.ASC ? -1 : 1;
      }
      return 0;
    });
  }, [filteredData, sortConfig]);

  const paginatedData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return sortedData.slice(start, end);
  }, [sortedData, page, rowsPerPage]);

  const requestSort = (key: string) => {
    setSortConfig((prevSortConfig) => {
      if (prevSortConfig?.key === key) {
        return prevSortConfig.direction === SortDirection.ASC
          ? { key, direction: SortDirection.DESC }
          : null;
      }
      return { key, direction: SortDirection.ASC };
    });
  };

  const onFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterKeyword(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(rowId)) {
        newExpandedRows.delete(rowId);
      } else {
        newExpandedRows.add(rowId);
      }
      return newExpandedRows;
    });
  };

  return {
    data: paginatedData,
    sortConfig,
    requestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    toggleRowExpansion,
    expandedRows,
    filterKeyword,
    setFilterKeyword,
    onFilter,
    hoveredColumn,
    setHoveredColumn,
  };
};

export default useTableUtils;
