import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { PaginationParams } from '../../types';
import { NoteAttachment } from '../types';

export const fetchTopicNotesAttachments = (
  topicExtractId: string,
  payload: PaginationParams,
): Promise<NoteAttachment[]> => {
  return apiClient
    .get(`${BASE_API_URL}/notes_attachments/topic_extracts/${topicExtractId}`, {
      params: payload,
    })
    .then((response) => response.data);
};

const uploadFileHeaders = {
  'Content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryvgmh8mt3AQnvRh4g',
};

export const uploadNoteAttachmentToTopic = (topicExtractId: string, file: File) => {
  return apiClient
    .post(
      `${BASE_API_URL}/notes_attachments/topic_extracts/${topicExtractId}`,
      { file },
      { headers: uploadFileHeaders },
    )
    .then((response) => response.data);
};

export const uploadNoteAttachmentToPerson = (personExtractId: string, file: File) => {
  return apiClient
    .post(
      `${BASE_API_URL}/notes_attachments/people_extracts/${personExtractId}`,
      { file },
      { headers: uploadFileHeaders },
    )
    .then((response) => response.data);
};

export const fetchPresignedUrlNoteAttachment = (
  noteAttachmentId: string,
): Promise<{ url: string }> => {
  return apiClient
    .get(`${BASE_API_URL}/notes_attachments/presigned_url/${noteAttachmentId}`)
    .then((response) => response.data);
};

export const deleteNoteAttachment = (noteAttachmentId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/notes_attachments/manage/${noteAttachmentId}`)
    .then((response) => response.data);
};

export const fetchPersonNotesAttachments = (
  personExtractId: string,
  payload: PaginationParams,
): Promise<NoteAttachment[]> => {
  return apiClient
    .get(`${BASE_API_URL}/notes_attachments/people_extracts/${personExtractId}`, {
      params: payload,
    })
    .then((response) => response.data);
};
