import { TopicResult } from '../../../services/api/search/types';

export enum Field {
  NAME = 'name',
  DESCRIPTION = 'description',
}

export interface TopicExtract {
  [Field.NAME]: string;
  [Field.DESCRIPTION]: string;
  url: string;
  id: string;
  matchScore: number;
  addedToFolder: boolean;
}

export const defaultRowsPerPage = 25;

export const filterTopicsByKeyword = (topic: TopicExtract, keyword: string) => {
  return (
    topic.name.toLowerCase().includes(keyword.toLowerCase()) ||
    topic.description.toLowerCase().includes(keyword.toLowerCase())
  );
};

export const exportHeaders = ['Topic', 'Description'];

export const exportFileName = 'trending_topics';

export const flattenTopicsExtracts = (topics: TopicResult[]) => {
  return topics.reduce((acc: TopicExtract[], current) => {
    if (current.extracts) {
      const extracts = current.extracts.map((extract) => ({
        [Field.NAME]: extract.topic,
        [Field.DESCRIPTION]: extract.reasoning,
        url: current.url,
        id: extract.id,
        matchScore: current.match_score,
        addedToFolder: extract.added_to_folder,
      }));

      return acc.concat(extracts);
    }
    return acc;
  }, []);
};
