import { useQuery } from '@tanstack/react-query';
import { fetchProjectDetails } from '../requests';

const useFetchProjectDetails = (projectId: string) => {
  return useQuery({
    queryKey: ['projectDetails'],
    queryFn: () => fetchProjectDetails(projectId),
  });
};

export default useFetchProjectDetails;
