import { useEffect, useState } from 'react';

const useLoadingOnValueChange = (value: any, delay = 500) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => setLoading(false), delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return loading;
};

export default useLoadingOnValueChange;
