import { useEffect, useRef } from 'react';

const useFocusOnCondition = <T extends HTMLElement>(shouldFocus: boolean) => {
  const elementRef = useRef<T>(null);

  useEffect(() => {
    if (shouldFocus && elementRef.current) {
      elementRef.current.focus();
    }
  }, [shouldFocus]);

  return elementRef;
};

export default useFocusOnCondition;
