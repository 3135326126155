import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from '../../requests';
import { PaginationParams } from '../../../types';

const useFetchUsers = (paginationParams: PaginationParams, enabled = true) => {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => {
      return fetchUsers(paginationParams);
    },
    initialData: { data: [], totalCount: '0' },
    enabled,
  });
};

export default useFetchUsers;
