import React, { useState } from 'react';
import { Typography, TextField, Button, Box, FormControl, Paper } from '@mui/material';

import styles from './PasswordReset.module.css';
import { useNavigate } from 'react-router-dom';
import { useSendResetPasswordLink } from '../../services/api/user-system/hooks';

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const { mutate: sendResetPasswordLink } = useSendResetPasswordLink();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendResetPasswordLink(email, {
      onSuccess: () => {
        setEmailSent(true);
      },
    });
  };

  return (
    <Box className={styles.container}>
      <Paper elevation={6} className={styles.paper}>
        {emailSent ? (
          <Box className={`${styles.emailSentContent} ${styles.fadeIn}`}>
            <Typography variant="h4" gutterBottom>
              Check your inbox
            </Typography>
            <Typography variant="body1" gutterBottom>
              We have sent you an email. Follow the instructions to access your account.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={styles.submitButton}
              onClick={() => navigate('/login')}
            >
              Back to login
            </Button>
          </Box>
        ) : (
          <Box className={styles.content}>
            <Typography variant="h4" component="h1" gutterBottom>
              Reset your password
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter the email address or username linked to your account and we will send you an
              email.
            </Typography>
            <FormControl className={styles.form} component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Send link
              </Button>
            </FormControl>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PasswordReset;
