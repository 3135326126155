import React, { useRef } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarType } from '../../enums';
import { useSnackbar } from '../snackbar';
import { refreshToken } from '../auth';

interface ErrorDetail {
  type: string;
  loc: string[];
  msg?: string;
  ctx?: {
    error: string;
  };
  input?: string;
}

const MAX_RETRY_ATTEMPTS = 3;

export const createQueryClient = (showSnackbar: (message: string, type: SnackbarType) => void) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error: any) => {
          if (error?.response?.status === 401) return false;
          return failureCount < MAX_RETRY_ATTEMPTS;
        },
      },
    },
    queryCache: new QueryCache({
      onError: async (error: any) => {
        const errorMessage = error?.response?.data?.detail;
        let errorToShow = '';

        if (Array.isArray(errorMessage)) {
          errorToShow = errorMessage.map((el: ErrorDetail) => el.msg).join('\n');
        } else {
          errorToShow = errorMessage || 'Something went wrong';
        }

        if (error?.response?.status === 401) {
          try {
            await refreshToken();
            return true;
          } catch (refreshError) {
            errorToShow = 'Failed to refresh token';
          }
        }

        showSnackbar(errorToShow, SnackbarType.ERROR);
        return false;
      },
    }),
  });
};

export const QueryClientInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showSnackbar } = useSnackbar();
  const queryClientRef = useRef<QueryClient | null>(null);

  if (!queryClientRef.current) {
    queryClientRef.current = createQueryClient(showSnackbar);
  }

  return <QueryClientProvider client={queryClientRef.current}>{children}</QueryClientProvider>;
};
