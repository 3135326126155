import { useQuery } from '@tanstack/react-query';
import { fetchGroups } from '../requests';
import { FetchGroupsPayload } from '../../types';

const useFetchGroups = (payload: FetchGroupsPayload) => {
  return useQuery({
    queryKey: ['groups'],
    queryFn: () => fetchGroups(payload),
    initialData: { data: [], totalCount: '0' },
  });
};

export default useFetchGroups;
