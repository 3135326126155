import React from 'react';
import { useCallback } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { DialogContentText } from '@mui/material';

const useDeleteItemConfirm = () => {
  const confirm = useConfirm();

  const showDeleteFolderConfirm = useCallback(
    (title: string, description: string) => {
      return confirm({
        title,
        titleProps: {
          sx: {
            fontSize: 30,
            fontWeight: 400,
          },
        },
        content: (
          <DialogContentText sx={{ fontSize: 20, color: '#000000DE' }}>
            {description}
          </DialogContentText>
        ),
        confirmationText: 'Remove',
        confirmationButtonProps: { variant: 'contained' },
      });
    },
    [confirm],
  );

  return showDeleteFolderConfirm;
};

export default useDeleteItemConfirm;
