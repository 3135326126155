import React, { useState } from 'react';
import { Typography, Button, Box, Paper } from '@mui/material';

import styles from './PasswordResetComplete.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmPasswordReset } from '../../services/api/user-system/hooks';
import PasswordForm from '../../components/user-system/PasswordForm/PasswordForm';
import CodeInput from '../../components/user-system/CodeInput/CodeInput';
import { useAuth } from '../../services/auth';

const codeLength = 6;

const PasswordResetComplete: React.FC = () => {
  const auth = useAuth();
  const { subId } = useParams();
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const { mutate: confirmPasswordReset } = useConfirmPasswordReset();

  const handleSubmit = (password: string) => {
    if (code.length !== codeLength) {
      setIsCodeValid(false);
    } else {
      setIsCodeValid(true);
      confirmPasswordReset(
        { subId: subId || '', password, confirmation_code: code },
        {
          onSuccess: (data) => {
            auth.signIn(data.email, password).then(() => setPasswordUpdated(true));
          },
        },
      );
    }
  };

  return (
    <Box className={styles.container}>
      <Paper className={styles.paper} elevation={6}>
        {passwordUpdated ? (
          <Box className={`${styles.updatedPasswordContainer} ${styles.fadeIn}`}>
            <Typography align="center" variant="h4" gutterBottom>
              You&apos;re all set
            </Typography>
            <Typography className={styles.updatedPasswordDescription} variant="body1" gutterBottom>
              Your password has been successfully updated, you are now logged in.
            </Typography>
            <Button
              onClick={() => navigate('/dashboard')}
              type="submit"
              variant="contained"
              color="primary"
            >
              Start research
            </Button>
          </Box>
        ) : (
          <>
            <Box className={styles.codeContainer}>
              <Typography className={styles.codeLabel} variant="h6">
                Enter code sent to your email address
              </Typography>
              <CodeInput isValid={isCodeValid} codeLength={codeLength} onChange={setCode} />
            </Box>
            <Typography className={styles.passwordLabel} variant="h6">
              Enter your new password
            </Typography>
            <PasswordForm handleSubmit={handleSubmit} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default PasswordResetComplete;
