import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  InputAdornment,
  Box,
} from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import styles from './AddFolderModal.module.css';

interface AddFolderModalProps {
  isOpen: boolean;
  onSubmit: (folderName: string) => void;
  onCancel: () => void;
}

const AddFolderModal: React.FC<AddFolderModalProps> = ({ isOpen, onSubmit, onCancel }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onTransitionEnd = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newFolderName.trim()) {
      onSubmit(newFolderName);
      setNewFolderName('');
      onCancel();
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onCancel}
      onTransitionEnd={onTransitionEnd}
    >
      <DialogTitle className={styles.title}>New Theme</DialogTitle>
      <DialogContent>
        <Box className={styles.content} onSubmit={handleSubmit} component="form">
          <TextField
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Enter a theme title..."
            variant="outlined"
            size="small"
            className={styles.textField}
            inputRef={inputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderOpenIcon />
                </InputAdornment>
              ),
              inputProps: { style: { padding: '16px 16px 16px 0' } },
            }}
          />
          <Box className={styles.buttons}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              className={styles.saveButton}
            >
              Save
            </Button>
            <Button
              size="large"
              onClick={onCancel}
              variant="outlined"
              className={styles.cancelButton}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddFolderModal;
