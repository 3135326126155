import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, TextField, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TagType } from '../TagManagementMenu/TagManagementMenu';
import { DotColors, TagColors } from '../../../constants/tagColorPalette';
import styles from './TagCustomizer.module.css';
import { Tag } from '../../../services/api/workspace/types';

interface TagCustomizerProps {
  onClose: () => void;
  onBack: () => void;
  onSave: (tag: TagType, id: string | null) => void;
  initialTag: Tag | null;
}

const TagCustomizer: React.FC<TagCustomizerProps> = ({ onBack, onClose, initialTag, onSave }) => {
  const [name, setName] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  useEffect(() => {
    if (initialTag) {
      setName(initialTag.name);
      setSelectedColor(initialTag.color);
    }
  }, [initialTag]);

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (name && selectedColor) {
      onSave({ name, color: selectedColor }, initialTag ? initialTag.id : null);
    }
  };

  const title = initialTag ? 'Edit tag' : 'Add tag';
  const submitButtonText = initialTag ? 'Save tag' : 'Create tag';

  return (
    <>
      <Box className={styles.header}>
        <IconButton onClick={onBack}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography color="text.secondary">{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <form onSubmit={handleSave}>
        <TextField
          className={styles.titleInput}
          placeholder="Title"
          size="small"
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Typography fontWeight="700" variant="body2" color="textSecondary" mb={1}>
          Select a colour
        </Typography>
        <Box className={styles.colorGrid}>
          {Object.values(TagColors).map((color) => (
            <Box
              sx={{ backgroundColor: DotColors[color] }}
              onClick={() => setSelectedColor(color)}
              key={color}
              className={styles.colorBox}
            >
              {selectedColor === color && <CheckIcon className={styles.checkIcon} />}
            </Box>
          ))}
        </Box>
        <Button type="submit" variant="contained">
          {submitButtonText}
        </Button>
      </form>
    </>
  );
};

export default TagCustomizer;
