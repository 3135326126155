import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SvgIcon, SvgIconProps } from '@mui/material';
import styles from './SpinningIcon.module.css';

interface SpinningIconProps extends SvgIconProps {
  className?: string;
}

export const SpinningIcon: React.FC<SpinningIconProps> = ({
  className,
  ...props
}: {
  className?: string;
}) => <SvgIcon component={AutorenewIcon} className={`${className} ${styles.spin}`} {...props} />;

export default SpinningIcon;
