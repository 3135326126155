import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { PaginationParams } from '../../types';
import { Project, ProjectDetails } from '../types';

export const createProject = (projectName: string, userId: string): Promise<Project> => {
  return apiClient
    .post(`${BASE_API_URL}/projects`, { name: projectName, user_id: userId })
    .then((response) => response.data);
};

export const fetchProjects = (payload: PaginationParams): Promise<Project[]> => {
  return apiClient
    .get(`${BASE_API_URL}/projects`, { params: payload })
    .then((response) => response.data);
};

export const fetchProjectDetails = (projectId: string): Promise<ProjectDetails> => {
  return apiClient.get(`${BASE_API_URL}/projects/${projectId}`).then((response) => response.data);
};

export const deleteProject = (projectId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/projects/${projectId}`)
    .then((response) => response.data);
};

export const updateProject = (projectId: string, name: string) => {
  return apiClient
    .put(`${BASE_API_URL}/projects/${projectId}`, { name })
    .then((response) => response.data);
};
