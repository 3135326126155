export enum NoteType {
  TEXT = 'text',
  FILE = 'file',
}

export interface MergedNote {
  id: string;
  content: string;
  created_at: string;
  type: NoteType;
}

const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10 MB
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const MAX_VIDEO_SIZE = 2 * 1024 * 1024 * 1024; // 2 GB

export const sizeByFileType = {
  'application/pdf': MAX_FILE_SIZE,
  'text/csv': MAX_FILE_SIZE,
  'image/jpg': MAX_IMAGE_SIZE,
  'image/jpeg': MAX_IMAGE_SIZE,
  'image/png': MAX_IMAGE_SIZE,
  'video/mp4': MAX_VIDEO_SIZE,
  'video/x-msvideo': MAX_VIDEO_SIZE,
  'video/mpeg': MAX_VIDEO_SIZE,
};
