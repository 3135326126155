import { useQuery } from '@tanstack/react-query';
import { fetchPersonDetails } from '../requests';
import { PersonDetails } from '../../types';

const useFetchPersonDetails = (folderId: string, peopleExtractId: string) => {
  return useQuery({
    queryKey: ['person_details'],
    queryFn: () => fetchPersonDetails(folderId, peopleExtractId),
    initialData: null,
    refetchInterval: (response) => {
      const data: PersonDetails | null | undefined = response.state.data;

      if (data && data.source_retrieval_status !== 'in progress') {
        return false;
      }

      return 5000;
    },
  });
};

export default useFetchPersonDetails;
