import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolder } from '../requests';

const useDeleteFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (folderId: string) => deleteFolder(folderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
    },
  });
};

export default useDeleteFolder;
