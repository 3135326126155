import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { PostHogProvider } from 'posthog-js/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './index.css';
import App from './App';

import posthogConfig from './services/posthogConfig';
import { SnackbarProvider } from './services/snackbar';
import { QueryClientInitializer } from './services/api/queryClient';

const container = document.getElementById('root');
if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <PostHogProvider client={posthogConfig}>
        <SnackbarProvider>
          <QueryClientInitializer>
            <StyledEngineProvider injectFirst>
              <App />
              <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
            </StyledEngineProvider>
          </QueryClientInitializer>
        </SnackbarProvider>
      </PostHogProvider>
    </React.StrictMode>,
  );
}
