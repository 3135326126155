import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  SelectChangeEvent,
  Avatar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import NotesSection from '../NotesSection/NotesSection';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DetailsModalActions from '../DetailsModalActions/DetailsModalActions';
import ItemInfoLabel from '../ItemInfoLabel';
import { useDeleteItemConfirm, useToggle } from '../../../hooks';
import WorkspaceItemMenu from '../WorkspaceItemMenu';
import { noop } from '../../../services/utils';

import { Priorities } from '../../../enums';
import PersonContactInfo from '../PersonContactInfo/PersonContactInfo';
import {
  useCreatePeopleNote,
  useFetchPeopleNotes,
} from '../../../services/api/workspace/notes/hooks';
import {
  useDeleteFolderPerson,
  useFetchPersonDetails,
  useUpdatePersonDetails,
} from '../../../services/api/workspace/people/hooks';
import {
  useFetchPersonNotesAttachments,
  useUploadNoteAttachmentToPerson,
} from '../../../services/api/workspace/notesAttachments/hooks';
import { useManagePersonTags } from '../../../services/api/workspace/tags/hooks';
import PersonActivitiesTable from '../PersonActivitiesTable/PersonActivitiesTable';
import DetailsExtractionStatus from '../DetailsExtractionStatus';
import { useUserSettingsStore } from '../../../stores';

interface PersonDetailsModalProps {
  open: boolean;
  onClose: () => void;
  isDefaultEditMode: boolean;
  toggleMoveToModal: () => void;
  personExtractId: string;
}

const generateLinkedin = (username: string) => `https://www.linkedin.com/in/${username}`;

const PersonDetailsModal: React.FC<PersonDetailsModalProps> = ({
  open,
  onClose,
  isDefaultEditMode,
  toggleMoveToModal,
  personExtractId,
}) => {
  const selectedFolderId = useUserSettingsStore((store) => store.selectedFolderId);
  const deletePersonConfirm = useDeleteItemConfirm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [showMore, toggleShowMore] = useToggle(false);
  const [noteText, setNoteText] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [formData, setFormData] = useState({
    jobTitle: '',
    phone: '',
    email: '',
  });

  const { data: notes } = useFetchPeopleNotes({
    people_extract_id: personExtractId,
  });

  const { data: personDetails, refetch } = useFetchPersonDetails(selectedFolderId, personExtractId);

  const { data: notesAttachments } = useFetchPersonNotesAttachments(personExtractId, {});

  const { mutate: deletePerson } = useDeleteFolderPerson();
  const { mutate: createPeopleNote } = useCreatePeopleNote();
  const { mutate: updatePersonDetails } = useUpdatePersonDetails();
  const { mutate: managePersonTags } = useManagePersonTags();
  const { mutate: uploadNoteAttachmentToPerson } = useUploadNoteAttachmentToPerson();

  useEffect(() => {
    if (personDetails) {
      setFormData({
        jobTitle: personDetails.job_title,
        email: personDetails.email || '',
        phone: personDetails.phone_number || '',
      });
    }
  }, [personDetails]);

  useEffect(() => {
    setIsEditMode(isDefaultEditMode);
  }, [isDefaultEditMode]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updatePersonDetails({
      personId: personExtractId,
      payload: {
        email: formData.email,
        job_title: formData.jobTitle,
        phone_number: formData.phone,
      },
    });

    setIsEditMode(false);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeletePerson = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    deletePersonConfirm('Are you sure you want to delete this person?', personDetails?.name || '')
      .then(() => deletePerson(personExtractId, { onSuccess: () => onClose() }))
      .catch(noop);
  };

  const handleMoveTo = () => {
    onClose();
    toggleMoveToModal();
  };

  const handleCreateTextNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (noteText.trim().length) {
      createPeopleNote({
        people_extract_id: personExtractId,
        text: noteText,
      });
      setNoteText('');
    }
  };

  const handleChangePriority = (event: SelectChangeEvent<string>) => {
    updatePersonDetails({
      personId: personExtractId,
      payload: { priority: event.target.value as Priorities },
    });
  };

  const description = personDetails?.people_extract.reasoning;

  const descriptionToDisplay = showMore
    ? personDetails?.people_extract.reasoning
    : `${description?.slice(0, 250)}...`;
  const showMoreButtonText = showMore ? 'Show less' : 'Show more';
  const ShowMoreIcon = showMore ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  const linkedInUsername = personDetails?.source_information?.source_username;
  const linkedInUrl = linkedInUsername ? generateLinkedin(linkedInUsername) : null;

  const handleManageTags = (tagsIdsToAdd: string[], tagsIdsToRemove: string[]) => {
    managePersonTags({
      personExtractId,
      tagsIdsToAdd,
      tagsIdsToRemove,
    });
  };

  const tags =
    personDetails?.folder_tags.map((folderTag) => ({
      ...folderTag.folder_tag,
      tagExtractId: folderTag.id,
    })) || [];

  const refetchPersonDetails = () => {
    refetch();
  };

  const handleUploadNoteAttachment = (file: File) => {
    uploadNoteAttachmentToPerson({ personExtractId, file });
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 24,
          right: 24,
          height: '100vh',
          bottom: 24,
          m: 0,
          borderRadius: '0',
        },
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
    >
      {personDetails && (
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box display="flex" mb={4}>
              <Box pr={2} flex="1">
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={personDetails.source_information?.photo_url}
                    style={{ marginRight: 8 }}
                    alt="avatar"
                  >
                    {personDetails.name.slice(0, 1)}
                  </Avatar>

                  <Typography variant="h5">{personDetails.name}</Typography>
                </Box>

                <Typography mt={2} mb={1}>
                  {personDetails.people_extract.core_interests}
                </Typography>
                {isEditMode ? (
                  <TextField
                    name="jobTitle"
                    style={{ marginBottom: 11, width: '100%' }}
                    size="small"
                    variant="standard"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                  />
                ) : (
                  <Typography mb={2} color="textSecondary">
                    {personDetails.job_title}
                  </Typography>
                )}
                <PersonContactInfo
                  linkedInUrl={linkedInUrl}
                  phone={formData.phone}
                  email={formData.email}
                  isEditMode={isEditMode}
                  handleInputChange={handleInputChange}
                />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" alignSelf="flex-start">
                  <DetailsExtractionStatus type={personDetails.source_retrieval_status} />
                  <IconButton onClick={handleOpenMenu}>
                    <MoreVertIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={onClose}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </Box>

                {isEditMode && (
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                )}
              </Box>
            </Box>
          </form>
          <DetailsModalActions
            priorityValue={personDetails.priority}
            onPriorityChange={handleChangePriority}
            tags={tags}
            manageTags={handleManageTags}
            refetchItemDetails={refetchPersonDetails}
          />
          <Box mb={3}>
            <Box mb={2} display="flex">
              <ItemInfoLabel
                style={{ width: '60%' }}
                title="Location"
                value={personDetails.source_information?.location}
                skeletonWidth={127}
              />

              <ItemInfoLabel
                value={personDetails.source_information?.company_source?.employees_count_range}
                skeletonWidth={40}
                title="Company size"
              />
            </Box>

            <Box mb={2} display="flex">
              <ItemInfoLabel
                skeletonWidth={80}
                style={{ width: '60%' }}
                title="Spoke at conferences"
                value={personDetails.people_extract.spoke_at_conference ? 'Yes' : 'No'}
              />

              <ItemInfoLabel
                skeletonWidth={80}
                title="Followers"
                value={personDetails.source_information?.followers_count}
              />
            </Box>
            <Box display="flex">
              <ItemInfoLabel
                style={{ width: '60%' }}
                skeletonWidth={80}
                title="Likes"
                value={personDetails.source_information?.followers_count}
              />
              <ItemInfoLabel
                skeletonWidth={80}
                title="Shares"
                value={personDetails.source_information?.followers_count}
              />
            </Box>
          </Box>

          <Typography fontWeight="700" variant="body2" color="textSecondary" mb={1}>
            Description
          </Typography>
          <Box display="flex" flexDirection="column" mb={2}>
            <Typography>{descriptionToDisplay}</Typography>
            {showMore && (
              <Box display="flex" flexDirection="column">
                <Typography fontWeight="700" variant="body2" color="textSecondary" mt={2} mb={2}>
                  Articles, posts and mentions
                </Typography>
                <PersonActivitiesTable
                  retrievalStatus={personDetails.source_retrieval_status}
                  personExtractId={personExtractId}
                />
              </Box>
            )}
            <Button
              onClick={toggleShowMore}
              sx={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}
            >
              {showMoreButtonText}
              <ShowMoreIcon color="primary" sx={{ ml: 1 }} />
            </Button>
          </Box>

          <NotesSection
            onSubmit={handleCreateTextNote}
            noteText={noteText}
            onChange={setNoteText}
            notes={notes}
            notesAttachments={notesAttachments}
            uploadNoteAttachment={handleUploadNoteAttachment}
            personExtractId={personExtractId}
          />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
      <WorkspaceItemMenu
        onDelete={handleDeletePerson}
        onEdit={() => setIsEditMode((prev) => !prev)}
        onMoveTo={handleMoveTo}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
      />
    </Dialog>
  );
};

export default PersonDetailsModal;
