import React from 'react';
import { TableRow, TableCell, Box, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styles from './ItemsNotFound.module.css';

const ItemsNotFound = () => {
  return (
    <TableRow>
      <TableCell colSpan={6} className={styles.tableCell}>
        <Box className={styles.contentBox}>
          <SearchIcon className={styles.searchIcon} />
          <Typography fontSize={20} mt={2}>
            Add topics and people
          </Typography>
          <Typography color="text.secondary" mt={1}>
            Add topics and people from your research...
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ItemsNotFound;
