import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ItemCount.module.css';

interface ItemCountProps {
  label: string | number;
}

const ItemCount: React.FC<ItemCountProps> = ({ label }) => {
  return (
    <Box className={styles.itemCount}>
      <Typography variant="body2" color="primary">
        {label}
      </Typography>
    </Box>
  );
};

export default ItemCount;
