export const TagColors = {
  WHITE: '#FAFAFA',
  BROWN: '#EFEBE9',
  ORANGE: '#FFF3E0',
  LIME: '#F9FBE7',
  GREEN: '#E8F5E9',
  TEAL: '#E0F2F1',
  BLUE: '#E3F2FD',
  INDIGO: '#E8EAF6',
  PURPLE: '#F3E5F5',
  RED: '#FFEBEE',
};

export const DotColors = {
  [TagColors.WHITE]: '#9E9E9E',
  [TagColors.BROWN]: '#795548',
  [TagColors.ORANGE]: '#FF9800',
  [TagColors.LIME]: '#CDDC39',
  [TagColors.GREEN]: '#4CAF50',
  [TagColors.TEAL]: '#009688',
  [TagColors.BLUE]: '#2196F3',
  [TagColors.INDIGO]: '#3F51B5',
  [TagColors.PURPLE]: '#9C27B0',
  [TagColors.RED]: '#F44336',
};
