import React from 'react';
import { Box, IconButton, TextField, InputAdornment } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import styles from './TableToolbar.module.css';
import { useUserSettingsStore } from '../../../stores';
import SearchIcon from '@mui/icons-material/Search';
import ToolbarExport from '../../shared/ToolbarExport';

interface TableToolbarProps {
  onExportCSV?: () => void;
  onExportExcel?: () => void;
  onFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TableToolbar: React.FC<TableToolbarProps> = ({ onExportCSV, onExportExcel, onFilter }) => {
  const { isSearchActive, toggleSearch } = useUserSettingsStore();

  return (
    <Box className={styles.container}>
      <Box className={styles.tools}>
        <ToolbarExport onExportCSV={onExportCSV} onExportExcel={onExportExcel} />
        <IconButton color="primary" edge="end" aria-label="filter" onClick={toggleSearch}>
          <FilterAltIcon />
        </IconButton>
      </Box>

      {isSearchActive && (
        <TextField
          label="Filter by keyword"
          size="small"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onFilter}
          style={{ marginRight: 10 }}
        />
      )}
    </Box>
  );
};

export default TableToolbar;
