import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFolderTopic } from '../requests';

const useDeleteFolderTopic = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (topicId: string) => deleteFolderTopic(topicId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
  });
};

export default useDeleteFolderTopic;
