import React, { useState } from 'react';

import { Box, IconButton, Paper, SelectChangeEvent, Typography } from '@mui/material';
import SelectPriority from '../SelectPriority';
import TagIndicator from '../TagIndicator/TagIndicator';
import AddIcon from '@mui/icons-material/Add';
import TagManagementMenu from '../TagManagementMenu/TagManagementMenu';

import styles from './DetailsModalActions.module.css';
import { DotColors } from '../../../constants/tagColorPalette';
import { FolderTag } from '../../../services/api/workspace/types';
import { Priorities } from '../../../enums';
import { useDeleteTagFromExtract } from '../../../services/api/workspace/tags/hooks';

interface DetailsModalActionsProps {
  priorityValue: Priorities;
  onPriorityChange: (event: SelectChangeEvent<string>) => void;
  tags: (FolderTag & { tagExtractId: string })[];
  manageTags: (idsToAdd: string[], idsToRemove: string[]) => void;
  refetchItemDetails: () => void;
}

const DetailsModalActions: React.FC<DetailsModalActionsProps> = ({
  onPriorityChange,
  priorityValue,
  tags = [],
  manageTags,
  refetchItemDetails,
}) => {
  const { mutate: deleteTagFromExtract } = useDeleteTagFromExtract();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (tagExtractId: string) => {
    deleteTagFromExtract(tagExtractId, { onSuccess: () => refetchItemDetails() });
  };

  return (
    <Paper className={styles.actionsContainer} variant="outlined">
      <Box className={styles.priorityContainer}>
        <Typography color="grey" variant="body2" fontWeight={700}>
          Priority
        </Typography>
        <SelectPriority value={priorityValue} onChange={onPriorityChange} />
      </Box>
      <Box className={styles.tagContainer}>
        <Typography className={styles.tagLabel} variant="body2">
          Tag
        </Typography>
        <Box className={styles.tags}>
          {tags.map((tag) => (
            <TagIndicator
              key={tag.id}
              onCloseClick={() => handleDelete(tag.tagExtractId)}
              onClick={handleClick}
              backgroundColor={tag.color}
              dotColor={DotColors[tag.color]}
              label={tag.name}
              style={{ marginTop: 8, marginRight: 8 }}
            />
          ))}

          <IconButton onClick={handleClick}>
            <AddIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <TagManagementMenu
        manageTags={manageTags}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        initialSelectedTags={tags}
      />
    </Paper>
  );
};

export default DetailsModalActions;
