import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFolder } from '../requests';

const useUpdateFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      folderId,
      payload,
    }: {
      folderId: string;
      payload: { name?: string; order?: number };
    }) => updateFolder(folderId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
    },
  });
};

export default useUpdateFolder;
