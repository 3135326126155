import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import posthog from 'posthog-js';

import cognitoConfig from '../config/aws-cognito-config';
import { noop } from './utils';
import { Box, CircularProgress } from '@mui/material';
import { setAuthToken, setProjectId } from './api/apiClient';
import { fetchMe } from './api/user-system/requests';
import { User } from './api/user-system/types';
import { useUserSettingsStore } from '../stores';
import { fetchProjects } from './api/workspace/projects/requests';

const defaultAuthContext: AuthContextType = {
  isAuthenticated: false,
  currentUser: null,
  getContextUser: () => 'anonymous',
  loading: true,
  signIn: async () => noop(),
  signOut: noop,
};

interface AuthContextType {
  isAuthenticated: boolean;
  currentUser: User | null;
  getContextUser: () => string;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const userPool = new CognitoUserPool(cognitoConfig);

export const refreshToken = async (): Promise<string> => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    throw new Error('No current user');
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
      if (err || !session) {
        return reject(new Error(`Failed to get session: ${err.message}`));
      }

      cognitoUser.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => {
        if (refreshErr) {
          return reject(new Error(`Failed to refresh session: ${refreshErr.message}`));
        }

        const newToken = newSession.getAccessToken().getJwtToken();
        setAuthToken(newToken);
        resolve(newToken);
      });
    });
  });
};

export const signIn = (email: string, password: string) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    posthog.reset();
  }
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { selectedProjectId, setSelectedProjectId } = useUserSettingsStore();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession(async (err: Error, session: any) => {
          setIsAuthenticated(!err && session.isValid());
          if (!err && session.isValid()) {
            const accessToken = session.getAccessToken().getJwtToken();
            posthog.identify(user.getUsername());
            setAuthToken(accessToken);
            try {
              const data = await fetchMe();
              if (selectedProjectId) {
                setProjectId(selectedProjectId);
              } else {
                fetchProjects({}).then((projects) => {
                  if (projects.length) {
                    setSelectedProjectId(projects[0].id);
                  }
                });
              }

              setCurrentUser(data);
            } catch (error) {
              console.error('Error fetching user data', error);
              setCurrentUser(null);
            }
          } else {
            setCurrentUser(null);
          }
          setLoading(false);
        });
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [selectedProjectId, setSelectedProjectId]);

  const getContextUser = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      return user.getUsername();
    }
    return 'anonymous';
  };

  const handleSignIn = async (email: string, password: string) => {
    try {
      await signIn(email, password);
      setIsAuthenticated(true);
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((err: Error, session: any) => {
          if (!err && session.isValid()) {
            const accessToken = session.getAccessToken().getJwtToken();
            setAuthToken(accessToken);
          }
        });
        fetchMe().then((data) => setCurrentUser(data));
        fetchProjects({}).then((projects) => {
          if (projects.length) {
            setSelectedProjectId(projects[0].id);
          }
        });
      }
    } catch (error) {
      console.error('Sign in error', error);
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  const handleSignOut = () => {
    signOut();
    setIsAuthenticated(false);
    setCurrentUser(null);
  };

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      currentUser,
      getContextUser,
      loading,
      signIn: handleSignIn,
      signOut: handleSignOut,
    }),
    [isAuthenticated, currentUser, loading],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

// Hook to use the auth context
export const useAuth = () => useContext(AuthContext);
