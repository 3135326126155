import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '../../requests';
import { UserUpdateFormFields } from '../../types';

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, payload }: { userId: string; payload: UserUpdateFormFields }) =>
      updateUser(userId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export default useUpdateUser;
