import React from 'react';
import { Box, Typography, Skeleton, Tabs, Tab, Paper, IconButton } from '@mui/material';
import styles from './DashboardSkeleton.module.css';

const DashboardSkeleton: React.FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Skeleton variant="rectangular" width={300} height={40} />

        <Box className={styles.textWithIcon}>
          <Skeleton style={{ marginRight: 20 }} variant="rectangular" width={300} height={30} />
          <IconButton>
            <Skeleton variant="circular" width={24} height={24} />
          </IconButton>
        </Box>
      </Box>

      <Tabs value={0} aria-label="tabs">
        <Tab label={<Skeleton width={50} />} />
        <Tab label={<Skeleton width={50} />} />
      </Tabs>
      <Paper className={styles.container}>
        <Box className={styles.textWithIcon}>
          <IconButton>
            <Skeleton variant="circular" width={30} height={30} />
          </IconButton>
          <Typography variant="h3" component="div">
            <Skeleton width={250} />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" padding={2}>
          <Skeleton variant="rectangular" width={100} height={30} />
          <Skeleton variant="rectangular" width={200} height={30} />
        </Box>
        <Box padding={2}>
          <Skeleton variant="rectangular" width="100%" height={400} />
        </Box>
      </Paper>
    </Box>
  );
};

export default DashboardSkeleton;
