import React, { useState } from 'react';
import { Box, Menu, MenuItem, IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface ToolbarExportProps {
  onExportCSV?: () => void;
  onExportExcel?: () => void;
}

const ToolbarExport: React.FC<ToolbarExportProps> = ({ onExportCSV, onExportExcel }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton color="primary" edge="end" aria-label="export" onClick={handleClick}>
        <FileUploadIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {onExportCSV && (
          <MenuItem
            onClick={() => {
              onExportCSV();
              handleClose();
            }}
          >
            Export CSV
          </MenuItem>
        )}
        {onExportExcel && (
          <MenuItem
            onClick={() => {
              onExportExcel();
              handleClose();
            }}
          >
            Export Excel
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default ToolbarExport;
