import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFolder } from '../requests';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';

const useCreateFolder = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (folderName: string) => createFolder(folderName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      showSnackbar(Messages.SUCCESS.FOLDER_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateFolder;
