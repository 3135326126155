import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadNoteAttachmentToPerson } from '../requests';
import { SnackbarType } from '../../../../../enums';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';

const useUploadNoteAttachmentToPerson = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ personExtractId, file }: { personExtractId: string; file: File }) =>
      uploadNoteAttachmentToPerson(personExtractId, file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes_attachments'] });
      showSnackbar(Messages.SUCCESS.NOTE_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useUploadNoteAttachmentToPerson;
