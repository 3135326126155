import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarType } from '../../enums';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

interface SnackbarAlertProps {
  open: boolean;
  message: string;
  type: SnackbarType;
  onClose: () => void;
  autoHideDuration?: number;
}

const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  open,
  message,
  type,
  onClose,
  autoHideDuration = 6000,
}) => {
  const messageToDisplay = message.slice(0, 300);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      style={{ zIndex: 1350 }}
    >
      <Alert onClose={onClose} severity={type}>
        {messageToDisplay}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
