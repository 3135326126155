import React, { useState } from 'react';
import { MenuItem, Menu, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import PriorityIndicator from './PriorityIndicator';
import { Priorities } from '../../enums';

interface PriorityFilterMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSelect: (priority: Priorities[]) => void;
  selectedPriorities: Priorities[];
}

const priorities = [
  { value: Priorities.HIGH, label: Priorities.HIGH },
  { value: Priorities.MEDIUM, label: Priorities.MEDIUM },
  { value: Priorities.LOW, label: Priorities.LOW },
  { value: Priorities.NOT_SET, label: Priorities.LOW },
];

const PriorityFilterMenu: React.FC<PriorityFilterMenuProps> = ({
  anchorEl,
  onClose,
  onSelect,
  selectedPriorities,
}) => {
  const [localSelectedPriorities, setLocalSelectedPriorities] =
    useState<Priorities[]>(selectedPriorities);

  const handleToggle = (priority: Priorities) => {
    const updatedPriorities = localSelectedPriorities.includes(priority)
      ? localSelectedPriorities.filter((p) => p !== priority)
      : [...localSelectedPriorities, priority];
    setLocalSelectedPriorities(updatedPriorities);
  };

  const handleClose = () => {
    onSelect(localSelectedPriorities);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <FormGroup>
        {priorities.map(({ value }) => (
          <MenuItem sx={{ display: 'flex', alignItems: 'center' }} key={value}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ marginRight: 8, marginTop: 2 }}
                  sx={{ mr: 1 }}
                  checked={localSelectedPriorities.includes(value)}
                  onChange={() => handleToggle(value)}
                />
              }
              label={<PriorityIndicator type={value} />}
            />
          </MenuItem>
        ))}
      </FormGroup>
    </Menu>
  );
};

export default PriorityFilterMenu;
