import React, { useState } from 'react';
import { MenuItem, Menu, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import TagIndicator from './TagIndicator/TagIndicator';
import { DotColors } from '../../constants/tagColorPalette';
import { Tag } from '../../services/api/workspace/types';

interface TagFilterMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSelect: (tags: string[]) => void;
  availableTags: Tag[];
  selectedTagNames: string[];
}

const TagFilterMenu: React.FC<TagFilterMenuProps> = ({
  anchorEl,
  onClose,
  onSelect,
  availableTags,
  selectedTagNames,
}) => {
  const [localSelectedTags, setLocalSelectedTags] = useState<string[]>(selectedTagNames);

  const handleToggle = (tagName: string) => {
    const updatedPriorities = localSelectedTags.includes(tagName)
      ? localSelectedTags.filter((t) => t !== tagName)
      : [...localSelectedTags, tagName];
    setLocalSelectedTags(updatedPriorities);
  };

  const handleClose = () => {
    onSelect(localSelectedTags);
    onClose();
  };

  return (
    <Menu autoFocus={false} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <FormGroup>
        {availableTags.map((tag) => (
          <MenuItem key={tag.color}>
            <FormControlLabel
              onChange={() => handleToggle(tag.name)}
              control={
                <Checkbox
                  style={{ marginRight: 8 }}
                  sx={{ mr: 1 }}
                  checked={localSelectedTags.includes(tag.name)}
                />
              }
              label={
                <TagIndicator
                  backgroundColor={tag.color}
                  dotColor={DotColors[tag.color]}
                  label={tag.name}
                />
              }
            />
          </MenuItem>
        ))}
      </FormGroup>
    </Menu>
  );
};

export default TagFilterMenu;
