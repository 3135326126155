import { useMutation } from '@tanstack/react-query';
import { confirmPasswordReset } from '../../requests';

const useConfirmPasswordReset = () => {
  return useMutation({
    mutationFn: ({
      subId,
      password,
      confirmation_code,
    }: {
      subId: string;
      password: string;
      confirmation_code: string;
    }) => confirmPasswordReset(subId, { confirmation_code, password }),
  });
};

export default useConfirmPasswordReset;
