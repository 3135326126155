import { useMutation, useQueryClient } from '@tanstack/react-query';
import { manageTopicFolders } from '../requests';
import { SnackbarType } from '../../../../../enums';
import { Messages } from '../../../../../constants';
import { useSnackbar } from '../../../../snackbar';

const useManageTopicFolders = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (payload: {
      topicExtractId: string;
      foldersIdsToAdd: string[];
      foldersIdsToRemove: string[];
    }) => manageTopicFolders(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fullSearch'] });
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      showSnackbar(Messages.SUCCESS.FOLDERS_SELECTIONS_UPDATED, SnackbarType.SUCCESS);
    },
  });
};

export default useManageTopicFolders;
