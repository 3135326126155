import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as HighPriorityIcon } from '../../assets/icons/priority_high.svg';
import { ReactComponent as MediumPriorityIcon } from '../../assets/icons/priority_medium.svg';
import { ReactComponent as LowPriorityIcon } from '../../assets/icons/priority_low.svg';
import { ReactComponent as NoPriorityIcon } from '../../assets/icons/priority_not_set.svg';
import { Priorities } from '../../enums';

interface PriorityIndicatorProps {
  type: Priorities;
}

const priorityConfig: Record<
  Priorities,
  { icon: React.FC<React.SVGProps<SVGSVGElement>>; label: string }
> = {
  [Priorities.HIGH]: { icon: HighPriorityIcon, label: 'High' },
  [Priorities.MEDIUM]: { icon: MediumPriorityIcon, label: 'Medium' },
  [Priorities.LOW]: { icon: LowPriorityIcon, label: 'Low' },
  [Priorities.NOT_SET]: { icon: NoPriorityIcon, label: 'Not set' },
};

const PriorityIndicator: React.FC<PriorityIndicatorProps> = ({ type }) => {
  const { icon: Icon, label } = priorityConfig[type];

  return (
    <Box display="flex" alignItems="center">
      <Icon style={{ marginRight: 16 }} />
      {label}
    </Box>
  );
};

export default PriorityIndicator;
