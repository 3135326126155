import { useMutation, useQueryClient } from '@tanstack/react-query';
import { moveTopicToAnotherGroup } from '../requests';

const useMoveTopicToAnotherGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: { folderId: string; extractId: string; groupIdDestination: string }) =>
      moveTopicToAnotherGroup(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
  });
};

export default useMoveTopicToAnotherGroup;
