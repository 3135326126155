import { Person, SearchDetails, Topic } from './types';
import { QueueLengthResponse } from './types';
import apiClient from '../apiClient';

const BASE_API_URL = process.env.REACT_APP_SIA_API_URL;

export const searchIndustry = (industryName: string) => {
  return apiClient
    .post(`${BASE_API_URL}/search`, { name: industryName })
    .then((response) => response.data);
};

export const runSearch = (searchTerm: string, topics?: Topic[], people?: Person[]) => {
  const payload = {
    search_term: searchTerm,
    topics,
    people,
  };

  return apiClient.post(`${BASE_API_URL}/search/create`, payload).then((response) => response.data);
};

export const fetchSearchesForUser = () => {
  return apiClient.get(`${BASE_API_URL}/searches`).then((response) => response.data);
};

export const fetchSearchById = (searchId: string) => {
  return apiClient.get(`${BASE_API_URL}/search/get/${searchId}`).then((response) => response.data);
};

export const deleteSearch = (searchId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/search/delete/${searchId}`)
    .then((response) => response.data);
};

export const fetchFullSearchById = (searchId: string): Promise<SearchDetails> => {
  return apiClient.get(`${BASE_API_URL}/search/full/${searchId}`).then((response) => response.data);
};

export const fetchTaskStatus = (taskId: string) => {
  return apiClient.get(`${BASE_API_URL}/search/status/${taskId}`).then((response) => response.data);
};

export const fetchQueueLength = (): Promise<QueueLengthResponse> => {
  return apiClient.get(`${BASE_API_URL}/queue_length`).then((response) => response.data);
};
