import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProject } from '../requests';
import { Project } from '../../types';

const useUpdateFolder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ projectId, name }: { projectId: string; name: string }) =>
      updateProject(projectId, name),
    onSuccess: (newProject: Project) => {
      queryClient.setQueryData(['projects'], (oldProjects: Project[]) => {
        return oldProjects.map((project) => (project.id === newProject.id ? newProject : project));
      });
    },
  });
};

export default useUpdateFolder;
