import React from 'react';
import { Box, Link, Skeleton, TextField, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

interface PersonContactInfoProps {
  linkedInUrl: string | null;
  phone: string;
  email: string;
  isEditMode: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonContactInfo: React.FC<PersonContactInfoProps> = ({
  linkedInUrl,
  phone,
  email,
  isEditMode,
  handleInputChange,
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" mb={2} color="primary">
        <LinkedInIcon color="primary" />
        {linkedInUrl ? (
          <Link href={linkedInUrl} target="_blank" display="flex" alignItems="center">
            <Typography ml={1}>{linkedInUrl}</Typography>
          </Link>
        ) : (
          <Skeleton variant="text" width={200} sx={{ ml: 1 }} />
        )}
      </Box>

      <Box style={{ marginBottom: isEditMode ? 11 : 16 }} display="flex" alignItems="center">
        <LocalPhoneIcon style={{ marginRight: 8 }} color="primary" />
        {!phone && <Skeleton variant="text" width={80} />}
        {phone && isEditMode && (
          <TextField
            name="phone"
            style={{ width: '100%' }}
            size="small"
            variant="standard"
            value={phone}
            onChange={handleInputChange}
          />
        )}
        {phone && !isEditMode && (
          <Link color="primary" href={`tel:${phone}`}>
            {phone}
          </Link>
        )}
      </Box>

      <Box style={{ marginBottom: isEditMode ? 11 : 16 }} display="flex" alignItems="center">
        <EmailIcon style={{ marginRight: 8 }} color="primary" />
        {!email && <Skeleton variant="text" width={140} />}
        {email && isEditMode && (
          <TextField
            name="email"
            fullWidth
            size="small"
            variant="standard"
            value={email}
            onChange={handleInputChange}
          />
        )}
        {email && !isEditMode && (
          <Link color="primary" href={`mailto:${email}`}>
            {email}
          </Link>
        )}
      </Box>
    </>
  );
};

export default PersonContactInfo;
