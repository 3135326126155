import { useMutation } from '@tanstack/react-query';
import { verifyUser } from '../../requests';

const useVerifyUser = () => {
  return useMutation({
    mutationFn: ({ subId, confirmationCode }: { subId: string; confirmationCode: string }) =>
      verifyUser(subId, confirmationCode),
  });
};

export default useVerifyUser;
