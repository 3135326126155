import React from 'react';
import { Navigate } from 'react-router-dom';
import { Dashboard } from '../pages';
import Workspace from '../pages/Workspace/Workspace';
import BackOffice from '../pages/BackOffice/BackOffice';

const privateRoutes = [
  { path: '/', element: <Navigate to="/dashboard" replace /> },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/workspace', element: <Workspace /> },
  { path: '/backoffice', element: <BackOffice />, superUserOnly: true },
];

export default privateRoutes;
