import { useQuery } from '@tanstack/react-query';
import { fetchTopicNotes } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchTopicNotes = (payload: PaginationParams & { topic_extract_id: string }) => {
  return useQuery({
    queryKey: ['notes', payload.topic_extract_id],
    queryFn: () => fetchTopicNotes(payload),
    initialData: [],
  });
};

export default useFetchTopicNotes;
