import React from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import QueueIcon from '@mui/icons-material/Queue';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Status } from '../../../enums';
import SpinningIcon from '../../shared/SpinningIcon/SpinningIcon';

type ColorKeys = 'warning' | 'error' | 'success' | 'info' | 'primary' | 'secondary' | 'default';

type Variant = 'outlined' | 'filled';

export const getStatusChipProps = (
  status: string,
  label: string,
): {
  label: string;
  variant: Variant;
  icon: React.ReactElement;
  color: ColorKeys;
} => {
  const baseProps: { label: string; variant: 'outlined' | 'filled' } = {
    label: `${label}: ${status}`,
    variant: 'outlined',
  };
  switch (status) {
    case Status.PENDING:
      return { ...baseProps, icon: <HourglassEmptyIcon />, color: 'warning' };
    case Status.QUEUED:
      return { ...baseProps, icon: <QueueIcon />, color: 'warning' };
    case Status.PROCESSING:
      return {
        ...baseProps,
        icon: <SpinningIcon fontSize="small" />,
        color: 'primary',
      };
    case Status.COMPLETE:
      return {
        ...baseProps,
        icon: <CheckCircleOutlineIcon />,
        color: 'success',
      };
    default:
      return { ...baseProps, icon: <ErrorOutlineIcon />, color: 'error' };
  }
};
