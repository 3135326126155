import React, { useState } from 'react';
import { Box, Button, FormControl } from '@mui/material';
import styles from './PasswordForm.module.css';
import PasswordInput from '../../shared/PasswordInput/PasswordInput';
import PasswordValidationRules from '../PasswordValidationRules/PasswordValidationRules';
import { useGeneratePassword } from '../../../services/api/user-system/hooks';
import { PasswordFormFields, passwordFormInitialValues, rules } from './PasswordForm.utils';

interface PasswordFormProps {
  handleSubmit: (password: string) => void;
}

const PasswordForm: React.FC<PasswordFormProps> = ({ handleSubmit }) => {
  const [passwordFormData, setPasswordFormData] =
    useState<PasswordFormFields>(passwordFormInitialValues);
  const [showValidationRules, setShowValidationRules] = useState(false);
  const [bluredConfirmPasswordInput, setBluredConfirmPasswordInput] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { refetch: fetchGeneratedPassword } = useGeneratePassword({
    enabled: false,
  });

  const handlePasswordFormValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordFormData({
      ...passwordFormData,
      [name]: value,
    });
  };

  const handlePasswordFocus = () => {
    setShowValidationRules(true);
  };

  const handleConfirmPasswordBlur = () => {
    setBluredConfirmPasswordInput(true);
  };

  const handlePasswordValidationChange = (isValid: boolean) => {
    setIsPasswordValid(isValid);
  };

  const handleFetchGeneratedPassword = async () => {
    const response = await fetchGeneratedPassword();
    const password = response.data.password;

    setPasswordFormData({
      password,
      confirmPassword: password,
    });

    setShowValidationRules(true);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (passwordFormData.password === passwordFormData.confirmPassword && isPasswordValid) {
      handleSubmit(passwordFormData.password);
    }
  };

  const showConfirmPasswordError =
    bluredConfirmPasswordInput && passwordFormData.password !== passwordFormData.confirmPassword;

  return (
    <Box className={styles.passwordContainer}>
      <FormControl component="form" onSubmit={onSubmit}>
        <PasswordInput
          name="password"
          label="Password"
          value={passwordFormData.password}
          onChange={handlePasswordFormValueChange}
          onFocus={handlePasswordFocus}
        />
        {showValidationRules && (
          <PasswordValidationRules
            onValidationChange={handlePasswordValidationChange}
            password={passwordFormData.password}
            rules={rules}
          />
        )}
        <PasswordInput
          name="confirmPassword"
          label="Confirm password"
          value={passwordFormData.confirmPassword}
          error={showConfirmPasswordError}
          helperText={
            showConfirmPasswordError ? 'Make sure this matches your master password.' : ''
          }
          onChange={handlePasswordFormValueChange}
          onBlur={handleConfirmPasswordBlur}
        />
        <Box className={styles.buttons}>
          <Button
            onClick={handleFetchGeneratedPassword}
            variant="contained"
            className={styles.generateButton}
          >
            Generate Password
          </Button>
          <Button className={styles.submitButton} type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default PasswordForm;
