import React, { useState } from 'react';
import { Typography, Grid, Box, Popover } from '@mui/material';
import moment from 'moment';
import { SearchDetails } from '../../../services/api/search/types';

import { Result, statusToColor, statusesInfo } from './SearchStatusCard.utils';
import styles from './SearchStatusCard.module.css';

interface SearchStatusCardProps {
  searchDetails: SearchDetails;
}

const SearchStatusCard: React.FC<SearchStatusCardProps> = ({ searchDetails }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedUrl, setSelectedUrl] = useState('');

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, url: string) => {
    setAnchorEl(event.currentTarget as HTMLDivElement);
    setSelectedUrl(url);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderStatusMatrix = (results: Result[]) => {
    return results.map((result, index) => (
      <Box
        key={index}
        className={styles.status}
        sx={{
          backgroundColor: statusToColor(result.extraction_status),
        }}
        onClick={(e) => handleClick(e, result.url || 'URL not available')}
      />
    ));
  };

  const renderStatusInfo = () => {
    return statusesInfo.map((status, index) => (
      <Box className={styles.statusInfo} key={index} sx={{ ml: index === 0 ? 0 : 2 }}>
        <Box
          className={styles.statusInfoLegend}
          sx={{
            backgroundColor: status.color,
          }}
        />
        <Typography variant="body2">{status.text}</Typography>
      </Box>
    ));
  };

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="body2" color="text.secondary">
        <b>ID:</b> {searchDetails.search_id}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <b>Created:</b> {moment(searchDetails.created_at).format('DD MMM YYYY')}
      </Typography>

      <Typography className={styles.description} variant="body2" color="text.secondary">
        These tiles the articles we have found based on your query, we will assess and read them to
        find potential topics for your conference.
      </Typography>
      <Box className={styles.statusMatrixContainer}>
        {renderStatusMatrix(searchDetails.topic_results)}
      </Box>
      <Grid sx={{ mb: 8 }} item xs={12}>
        <Box className={styles.statusInfoContainer}>{renderStatusInfo()}</Box>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <a href={selectedUrl} target="_blank" rel="noopener noreferrer">
            {selectedUrl}
          </a>
        </Typography>
      </Popover>
    </Grid>
  );
};

export default SearchStatusCard;
