import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import styles from './PasswordValidationRules.module.css';

interface PasswordValidationRulesProps {
  password: string;
  onValidationChange: (isValid: boolean) => void;
  rules: { regex: RegExp; message: string }[];
}

const PasswordValidationRules: React.FC<PasswordValidationRulesProps> = ({
  password,
  onValidationChange,
  rules,
}) => {
  useEffect(() => {
    const allValid = rules.every((rule) => rule.regex.test(password));
    onValidationChange(allValid);
  }, [password, onValidationChange, rules]);

  return (
    <Box className={styles.container}>
      {rules.map((rule, index) => {
        const isValid = rule.regex.test(password);
        return (
          <Box
            className={styles.ruleBox}
            key={index}
            style={{ marginBottom: index === rules.length - 1 ? 0 : 16 }}
          >
            {isValid ? <DoneIcon color="success" /> : <ErrorIcon color="error" />}
            <Typography
              className={styles.ruleText}
              color={isValid ? 'textPrimary' : 'error'}
              variant="body2"
            >
              {rule.message}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default PasswordValidationRules;
