import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadNoteAttachmentToTopic } from '../requests';
import { SnackbarType } from '../../../../../enums';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';

const useUploadNoteAttachmentToTopic = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ topicExtractId, file }: { topicExtractId: string; file: File }) =>
      uploadNoteAttachmentToTopic(topicExtractId, file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes_attachments'] });
      showSnackbar(Messages.SUCCESS.NOTE_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useUploadNoteAttachmentToTopic;
