import React from 'react';
import { List, ListItem, ListItemText, IconButton, Skeleton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Search } from '../../../services/api/search/types';
import styles from './SearchHistoryList.module.css';
import SpinningIcon from '../../shared/SpinningIcon/SpinningIcon';
import { Status } from '../../../enums';

interface SearchHistoryListProps {
  searchHistory: Search[];
  selectedSearchId: string | null;
  setSelectedSearchId: (id: string) => void;
  handleDeleteSearch: (id: string, event: React.MouseEvent) => void;
  isLoading: boolean;
}

const SearchHistoryList: React.FC<SearchHistoryListProps> = ({
  searchHistory,
  selectedSearchId,
  setSelectedSearchId,
  handleDeleteSearch,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <List>
        {[...Array(6)].map((_, index) => (
          <ListItem key={index}>
            <ListItemText primary={<Skeleton variant="text" width="80%" />} />
            <Skeleton variant="circular" width={25} height={25} />
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <List>
      {searchHistory.map((search) => (
        <ListItem
          button
          key={search.id}
          onClick={() => setSelectedSearchId(search.id)}
          className={selectedSearchId === search.id ? styles.listItemSelected : ''}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={(e) => handleDeleteSearch(search.id, e)}
            >
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemText primary={search.term} />
          {search.searchStatus !== Status.COMPLETE && (
            <SpinningIcon color="primary" fontSize="small" />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default SearchHistoryList;
