import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTag } from '../requests';

const useUpdateTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ tagId, payload }: { tagId: string; payload: { name: string; color: string } }) =>
      updateTag(tagId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
  });
};

export default useUpdateTag;
