import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { PaginationParams } from '../../types';
import { Note } from '../types';

export const fetchPeopleNotes = (payload: PaginationParams & { people_extract_id: string }) => {
  const { people_extract_id, ...params } = payload;

  return apiClient
    .get(`${BASE_API_URL}/notes/people_extracts/${people_extract_id}`, { params })
    .then((response) => response.data);
};

export const fetchTopicNotes = (payload: PaginationParams & { topic_extract_id: string }) => {
  const { topic_extract_id, ...params } = payload;

  return apiClient
    .get(`${BASE_API_URL}/notes/topic_extracts/${topic_extract_id}`, { params })
    .then((response) => response.data);
};

export const createTopicNote = (topic_extract_id: string, text: string): Promise<Note[]> => {
  return apiClient
    .post(`${BASE_API_URL}/notes/topic_extracts/${topic_extract_id}`, { text })
    .then((response) => response.data);
};

export const createPeopleNote = (people_extract_id: string, text: string): Promise<Note[]> => {
  return apiClient
    .post(`${BASE_API_URL}/notes/people_extracts/${people_extract_id}`, { text })
    .then((response) => response.data);
};

export const deleteNoteFromPersonExtract = (noteId: string, personExtractId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/notes/${noteId}/people_extracts/${personExtractId}`)
    .then((response) => response.data);
};

export const deleteNoteFromTopicExtract = (noteId: string, topicExtractId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/notes/${noteId}/topic_extracts/${topicExtractId}`)
    .then((response) => response.data);
};
