import { useMutation } from '@tanstack/react-query';
import { sendResetPasswordLink } from '../../requests';

const useSendResetPasswordLink = () => {
  return useMutation({
    mutationFn: (email: string) => sendResetPasswordLink(email),
  });
};

export default useSendResetPasswordLink;
