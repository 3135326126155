import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { PaginationParams } from '../../types';
import { Activity, PersonDetails, UpdatePersonDetailsPayload } from '../types';

export const deleteFolderPerson = (personId: string) => {
  return apiClient
    .delete(`${BASE_API_URL}/folders/people_extracts/${personId}`)
    .then((response) => response.data);
};

export const fetchPersonDetails = (
  folderId: string,
  personExtractId: string,
): Promise<PersonDetails> => {
  return apiClient
    .get(`${BASE_API_URL}/folders/${folderId}/people_extracts/${personExtractId}`)
    .then((response) => response.data);
};

export const updatePersonDetails = (personId: string, payload: UpdatePersonDetailsPayload) => {
  return apiClient
    .put(`${BASE_API_URL}/folders/people_extracts/${personId}`, payload)
    .then((response) => response.data);
};

export const movePersonToAnotherFolder = ({
  folderId,
  extractId,
  folderIdDestination,
}: {
  folderId: string;
  extractId: string;
  folderIdDestination: string;
}) => {
  return apiClient
    .put(
      `${BASE_API_URL}/folders/${folderId}/people_extracts/${extractId}/move-to-folder/${folderIdDestination}`,
    )
    .then((response) => response.data);
};

export const movePersonToAnotherGroup = ({
  folderId,
  extractId,
  groupIdDestination,
}: {
  folderId: string;
  extractId: string;
  groupIdDestination: string;
}) => {
  return apiClient
    .put(
      `${BASE_API_URL}/folders/${folderId}/people_extracts/${extractId}/move-to-group/${groupIdDestination}`,
    )
    .then((response) => response.data);
};

export const fetchPersonActivities = (
  folderId: string,
  personExtractId: string,
  payload: PaginationParams,
): Promise<{ data: Activity[]; totalCount: string }> => {
  return apiClient
    .get(`${BASE_API_URL}/folders/${folderId}/people_extracts/${personExtractId}/activities`, {
      params: payload,
    })
    .then((response) => ({ data: response.data, totalCount: response.headers['x-total-count'] }));
};

export const managePersonFolders = ({
  personExtractId,
  foldersIdsToAdd,
  foldersIdsToRemove,
}: {
  personExtractId: string;
  foldersIdsToAdd: string[];
  foldersIdsToRemove: string[];
}) => {
  return apiClient
    .put(`${BASE_API_URL}/manage-folders/people_extracts/${personExtractId}`, {
      to_add: foldersIdsToAdd,
      to_remove: foldersIdsToRemove,
    })
    .then((response) => response.data);
};
