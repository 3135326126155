import { useMutation } from '@tanstack/react-query';
import { setUserPassword } from '../../requests';

const useSetUserPassword = () => {
  return useMutation({
    mutationFn: ({
      subId,
      password,
      tempToken,
    }: {
      subId: string;
      password: string;
      tempToken: string;
    }) => setUserPassword(subId, password, tempToken),
  });
};

export default useSetUserPassword;
