const Messages = {
  SUCCESS: {
    FOLDER_PERSON_ADDED: 'Person was successfully added to theme!',
    FOLDER_PERSON_DELETED: 'Person was successfully deleted from theme!',
    FOLDER_TOPIC_ADDED: 'Topic was successfully added to theme!',
    FOLDER_TOPIC_DELETED: 'Topic was successfully deleted from theme!',
    FOLDERS_SELECTIONS_UPDATED: 'Your themes selection have been successfully updated!',
    SEARCH_STARTED: 'Search has been started successfully!',
    SEARCH_DELETED: 'Search has been deleted successfully!',
    FOLDER_CREATED: 'Theme was successfully created!',
    USER_CREATED: 'User was successfully invited!',
    EMAIL_RESENT: 'The email was successfully resent!',
    NOTE_CREATED: 'Note was successfully created!',
    TAG_CREATED: 'Tag was successfully created!',
    TAG_UPDATED: 'Tag was successfully updated!',
    PROJECT_CREATED: 'Event was successfully created!',
  },
};

export default Messages;
