import React, { useCallback, useEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Folder } from '../../../services/api/workspace/types';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CheckIcon from '@mui/icons-material/Check';
import styles from './PickFolderModal.module.css';
import { useFocusOnCondition } from '../../../hooks';
import { useCreateFolder } from '../../../services/api/workspace/folders/hooks';

interface MappedFolder {
  id: string;
  selected: boolean;
  initialSelected: boolean;
}

export interface PickFolderModalProps {
  open: boolean;
  onSave: (foldersIdsToAdd: string[], foldersIdsToRemove: string[]) => void;
  onClose: () => void;
  folders: Folder[];
  initialSelectedFoldersIds: string[];
}

const PickFolderModal: React.FC<PickFolderModalProps> = ({
  open,
  onSave,
  onClose,
  folders,
  initialSelectedFoldersIds,
}) => {
  const { mutate: createFolder } = useCreateFolder();
  const [mappedFolders, setMappedFolders] = useState<MappedFolder[]>([]);
  const [addingFolder, setAddingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [highlightedFolderId, setHighlightedFolderId] = useState<string | null>(null);

  const selectedFoldersIds = useMemo(
    () => mappedFolders.filter((folder) => folder.selected).map((folder) => folder.id),
    [mappedFolders],
  );

  const hasUpdatedSelection = useMemo(
    () => mappedFolders.some((folder) => folder.initialSelected !== folder.selected),
    [mappedFolders],
  );

  const inputRef = useFocusOnCondition(addingFolder);

  useEffect(() => {
    const newMappedFolders: MappedFolder[] = folders.map((folder) => {
      const selected = initialSelectedFoldersIds.some((folderId) => folder.id === folderId);
      return {
        id: folder.id,
        selected,
        initialSelected: selected,
      };
    });
    setMappedFolders(newMappedFolders);
  }, [open, folders, initialSelectedFoldersIds]);

  const resetModalState = useCallback(() => {
    setNewFolderName('');
    setAddingFolder(false);
  }, [setAddingFolder]);

  const handleClose = () => {
    onClose();
    resetModalState();
  };

  const handleFolderSelect = (folderId: string) => {
    setMappedFolders((prev) =>
      prev.map((folder) => {
        return folder.id === folderId ? { ...folder, selected: !folder.selected } : folder;
      }),
    );
  };

  const handleSave = () => {
    const foldersIdsToAdd = mappedFolders
      .filter((folder) => folder.selected && folder.selected !== folder.initialSelected)
      .map((folder) => folder.id);
    const foldersIdsToRemove = mappedFolders
      .filter((folder) => !folder.selected && folder.selected !== folder.initialSelected)
      .map((folder) => folder.id);
    onSave(foldersIdsToAdd, foldersIdsToRemove);
    handleClose();
  };

  const handleNewFolderSave = () => {
    if (newFolderName.trim()) {
      createFolder(newFolderName, {
        onSuccess: (newFolder: Folder) => {
          resetModalState();
          setHighlightedFolderId(newFolder.id);
          setTimeout(() => {
            setHighlightedFolderId(null);
          }, 800);
        },
      });
    }
  };

  useEffect(() => {
    setAddingFolder(!Boolean(folders.length));
  }, [folders]);

  const title = folders.length ? 'Choose themes' : 'Create a theme';

  const getFolderStyle = (folderId: string) => {
    if (folderId === highlightedFolderId) return styles.folderSuccess;
    if (selectedFoldersIds.includes(folderId)) return styles.selectedFolder;
    return styles.folder;
  };

  return (
    <Dialog
      className={styles.dialogContainer}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogContent className={styles.content}>
        <DialogTitle className={styles.title}>Add to workspace</DialogTitle>
        <Typography className={styles.description}>
          Organize your research by categorizing topics, people, and sponsors into themes on your
          workspace.
        </Typography>
        <Typography>{title}:</Typography>

        <List className={styles.list}>
          {folders.map((folder) => (
            <ListItem
              key={folder.id}
              disableGutters
              onClick={() => handleFolderSelect(folder.id)}
              className={styles.listItem}
            >
              <ListItemButton className={getFolderStyle(folder.id)}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  sx={{ mr: 1 }}
                  checked={selectedFoldersIds.includes(folder.id)}
                />
                <FolderOpenIcon className={styles.folderIcon} />
                <ListItemText primary={folder.name} />
                {highlightedFolderId === folder.name && (
                  <CheckIcon className={styles.folderCheckIcon} />
                )}
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem className={styles.addFolderBox}>
            {addingFolder ? (
              <>
                <TextField
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter a theme title..."
                  variant="outlined"
                  size="small"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleNewFolderSave();
                    }
                  }}
                  className={styles.textField}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FolderOpenIcon />
                      </InputAdornment>
                    ),
                    inputProps: { style: { padding: '16px 16px 16px 0' } },
                  }}
                />
                <Box className={styles.buttonBox}>
                  <Button
                    size="large"
                    onClick={handleNewFolderSave}
                    variant="contained"
                    color="primary"
                    className={styles.saveButton}
                  >
                    Save
                  </Button>
                  <Button
                    size="large"
                    onClick={resetModalState}
                    variant="outlined"
                    className={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Button
                onClick={() => setAddingFolder(true)}
                variant="contained"
                startIcon={<AddIcon className={styles.addIcon} />}
                className={styles.addFolderButton}
              >
                Add theme
              </Button>
            )}
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Button onClick={handleClose}>Close</Button>
        <Button disabled={!hasUpdatedSelection} variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickFolderModal;
