import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Box, Typography, Button, Link, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import 'react-quill/dist/quill.snow.css';
import { Note, NoteAttachment } from '../../../services/api/workspace/types';
import styles from './NotesSection.module.css';
import parse from 'html-react-parser';
import { fetchPresignedUrlNoteAttachment } from '../../../services/api/workspace/notesAttachments/requests';
import { MergedNote, NoteType, sizeByFileType } from './NotesSection.utils';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useDeleteNoteFromPersonExtract,
  useDeleteNoteFromTopicExtract,
} from '../../../services/api/workspace/notes/hooks';
import { useDeleteNoteAttachment } from '../../../services/api/workspace/notesAttachments/hooks';

interface NotesSectionProps {
  onChange: (value: string) => void;
  noteText: string;
  notes: Note[];
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  notesAttachments: NoteAttachment[];
  uploadNoteAttachment: (file: File) => void;
  topicExtractId?: string;
  personExtractId?: string;
}

const NotesSection: React.FC<NotesSectionProps> = ({
  onChange,
  noteText,
  notes,
  onSubmit,
  notesAttachments,
  uploadNoteAttachment,
  personExtractId,
  topicExtractId,
}) => {
  const { mutate: deleteNoteAttachment } = useDeleteNoteAttachment();
  const { mutate: deleteNoteFromPersonExtract } = useDeleteNoteFromPersonExtract();
  const { mutate: deleteNoteFromTopicExtract } = useDeleteNoteFromTopicExtract();

  const [isError, setIsError] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleAttachClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type as keyof typeof sizeByFileType;
      if (sizeByFileType[fileType] && file.size <= sizeByFileType[fileType]) {
        uploadNoteAttachment(file);
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  };

  const combinedNotes: MergedNote[] = useMemo(() => {
    return [
      ...notes.map((note) => ({
        id: note.id,
        content: note.text,
        created_at: note.created_at,
        type: NoteType.TEXT,
      })),
      ...notesAttachments.map((attachment) => ({
        id: attachment.id,
        content: attachment.file_name,
        created_at: attachment.created_at,
        type: NoteType.FILE,
      })),
    ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [notes, notesAttachments]);

  const handleFileClick = async (noteAttachmentId: string) => {
    try {
      const { url } = await fetchPresignedUrlNoteAttachment(noteAttachmentId);
      window.open(url);
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
    }
  };

  const handleDelete = (id: string, type: NoteType) => {
    if (type === NoteType.FILE) {
      deleteNoteAttachment(id);
    } else {
      topicExtractId
        ? deleteNoteFromTopicExtract({ noteId: id, topicExtractId })
        : deleteNoteFromPersonExtract({ noteId: id, personExtractId: personExtractId || '' });
    }
  };

  return (
    <Box>
      <Typography fontWeight="700" variant="body2" color="textSecondary" mb={1}>
        Notes
      </Typography>
      <form className={styles.form} onSubmit={onSubmit}>
        <ReactQuill style={{ height: '100%' }} theme="snow" value={noteText} onChange={onChange} />
        <Button type="submit" className={styles.submitButton} variant="contained">
          Submit
        </Button>
        <Button
          className={styles.attachButton}
          startIcon={<AttachFileIcon />}
          onClick={handleAttachClick}
        >
          Attach
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          formEncType="multipart/form-data"
          accept=".pdf,.csv,.jpeg,.jpg,.png,.mp4,.avi,.mpeg"
        />
      </form>
      <Typography className={`${styles.fileAttachTip} ${isError ? styles.fileAttachTipError : ''}`}>
        * Attach files (images, videos, PDFs, CSVs). Please note: images and files should be under
        10MB, videos under 2GB.
      </Typography>
      {combinedNotes.map((note, index) => (
        <Box
          key={note.id}
          style={{
            paddingBottom: 16,
            borderBottom: combinedNotes.length - 1 === index ? 'none' : '1px solid #E0E0E0',
            marginBottom: 16,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Box display="flex">
              {note.type === NoteType.FILE ? (
                <AttachFileIcon fontSize="small" />
              ) : (
                <TextSnippetIcon fontSize="small" />
              )}

              <Typography ml={1} fontWeight="700" variant="body2" color="textSecondary">
                {moment(note.created_at).format('DD/MM/YYYY, HH:mm')}
              </Typography>
            </Box>
            <IconButton onClick={() => handleDelete(note.id, note.type)}>
              <DeleteIcon />
            </IconButton>
          </Box>

          {note.type === NoteType.FILE ? (
            <Link component="button" onClick={() => handleFileClick(note.id)}>
              {note.content}
            </Link>
          ) : (
            parse(note.content, {
              replace: (domNode: any) => {
                // remove bottom indent from paragraph
                if (domNode.name === 'p') {
                  domNode.attribs = {
                    ...domNode.attribs,
                    className: styles.noMarginBottom,
                  };
                }
              },
            })
          )}
        </Box>
      ))}
    </Box>
  );
};

export default NotesSection;
