import posthog from 'posthog-js';

const { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } = process.env;

const posthogKey = REACT_APP_PUBLIC_POSTHOG_KEY || '';

posthog.init(posthogKey, {
  api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
});

export default posthog;
