import { useQuery } from '@tanstack/react-query';
import { fetchTags } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchTags = (payload: PaginationParams) => {
  return useQuery({
    queryKey: ['tags'],
    queryFn: () => fetchTags(payload),
    initialData: [],
  });
};

export default useFetchTags;
