import { useQuery } from '@tanstack/react-query';
import { fetchQueueLength } from '../requests';

const useQueueLength = () => {
  return useQuery({
    queryKey: ['queueLength'],
    queryFn: async () => {
      const { queue_length: responseQueueLength } = await fetchQueueLength();
      return responseQueueLength;
    },
  });
};

export default useQueueLength;
