import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import styles from './SearchBox.module.css';

interface SearchBoxProps {
  onSearch: (searchTerm: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, disabled = false, autoFocus = false }) => {
  const [query, setQuery] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!disabled && query.length) {
      onSearch(query);
    }
  };

  return (
    <Box
      component="form"
      className={styles.searchBox}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <TextField
        className={styles.textField}
        label="Search"
        variant="outlined"
        value={query}
        inputRef={inputRef}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        InputProps={{
          classes: {
            root: styles.inputRoot,
          },
          endAdornment: (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabled}
              className={styles.searchButton}
            >
              <SearchIcon sx={{ color: 'white' }} />
            </Button>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBox;
