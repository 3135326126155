import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

interface ItemInfoLabelProps {
  title: string;
  value?: string | number;
  skeletonWidth?: number;
  style?: React.CSSProperties;
}

const ItemInfoLabel: React.FC<ItemInfoLabelProps> = ({
  title,
  value,
  style,
  skeletonWidth = 40,
}) => {
  return (
    <Box style={style} display="flex" alignItems="center">
      <Typography fontWeight="700" variant="body2" color="textSecondary" mr={1}>
        {title}
      </Typography>
      {!value ? (
        <Skeleton variant="text" width={skeletonWidth} sx={{ ml: 1 }} />
      ) : (
        <Typography variant="body1">{value}</Typography>
      )}
    </Box>
  );
};

export default ItemInfoLabel;
