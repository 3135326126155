import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import { DetailsRetrievalStatus } from '../../services/api/workspace/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface DetailsExtractionStatusProps {
  type?: DetailsRetrievalStatus;
}
const statusConfig: Record<
  DetailsRetrievalStatus,
  {
    icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string;
    };
    label: string;
    color: string;
  }
> = {
  done: {
    color: '#2e7d32',
    icon: CheckCircleIcon,
    label: 'Data successfully retrieved',
  },
  'in progress': {
    color: '#1976d2',
    icon: InfoIcon,
    label: 'Information loading...',
  },
  partially_done: {
    icon: InfoIcon,
    color: '#ed6c02',
    label: 'Some data could not be retrieved',
  },
  failed: {
    icon: ErrorIcon,
    color: '#d32f2f',
    label: 'Data retrieval failed',
  },
  timeout: {
    icon: InfoIcon,
    color: '#ed6c02',
    label: 'Request timed out',
  },
};

const DetailsExtractionStatus: React.FC<DetailsExtractionStatusProps> = ({
  type = 'in progress',
}) => {
  const { color, label, icon: Icon } = statusConfig[type];

  return (
    <Box display="flex" alignItems="center">
      <Icon style={{ color }} />
      <Typography variant="body2" style={{ marginLeft: 4, color }}>
        {label}
      </Typography>
    </Box>
  );
};

export default DetailsExtractionStatus;
