import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSearch } from '../requests';
import { useSnackbar } from '../../../snackbar';
import { SnackbarType } from '../../../../enums';
import { Messages } from '../../../../constants';

const useDeleteSearch = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (searchId: string) => deleteSearch(searchId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['searches'] });
      showSnackbar(Messages.SUCCESS.SEARCH_DELETED, SnackbarType.SUCCESS);
    },
  });
};

export default useDeleteSearch;
