import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../../../../snackbar';
import { Messages } from '../../../../../constants';
import { SnackbarType } from '../../../../../enums';
import { createUser } from '../../requests';

const useCreateUser = () => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (email: string) => createUser(email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      showSnackbar(Messages.SUCCESS.USER_CREATED, SnackbarType.SUCCESS);
    },
  });
};

export default useCreateUser;
