export type PasswordFormFields = {
  password: string;
  confirmPassword: string;
};

export const rules = [
  {
    regex: /.{8,}/,
    message: 'Password must be at least 8 characters long.',
  },
  {
    regex: /[A-Z]/,
    message: 'Password must contain at least one uppercase letter.',
  },
  {
    regex: /[a-z]/,
    message: 'Password must contain at least one lowercase letter.',
  },
  {
    regex: /[0-9]/,
    message: 'Password must contain at least one number.',
  },
  {
    regex: /[!@#$%^&*(),.?":{}|<>]/,
    message: 'Password must contain at least one special character.',
  },
];

export const passwordFormInitialValues = {
  password: '',
  confirmPassword: '',
};
