import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNoteFromPersonExtract } from '../requests';

const useDeleteNoteFromPersonExtract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ noteId, personExtractId }: { noteId: string; personExtractId: string }) =>
      deleteNoteFromPersonExtract(noteId, personExtractId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notes'] });
    },
  });
};

export default useDeleteNoteFromPersonExtract;
