import { useQuery } from '@tanstack/react-query';
import { fetchFolders } from '../requests';
import { PaginationParams } from '../../../types';

const useFetchFolders = (payload: PaginationParams) => {
  return useQuery({
    queryKey: ['folders'],
    queryFn: () => fetchFolders(payload),
    initialData: [],
  });
};

export default useFetchFolders;
