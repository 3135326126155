import { PersonResult, TopicResult } from '../../../services/api/search/types';

export type Result = PersonResult | TopicResult;

export const statusToColor = (status: string) => {
  switch (status) {
    case 'hold':
      return '#eee';
    case 'pending':
      return '#bbb'; // orange
    case 'queued':
      return '#007bff'; // blue
    case 'complete':
      return '#28a745'; // green
    case 'error':
      return '#dc3545'; // red
    case 'lowgrade':
      return '#FFBF00'; // red
    default:
      return '#6c757d'; // grey
  }
};

export const statusesInfo = [
  { color: '#bbb', text: 'Queued for quality assessment' },
  { color: '#007bff', text: 'Reading Article' },
  { color: '#28a745', text: 'Article read successfully' },
  { color: '#dc3545', text: 'Issue reading article' },
  { color: '#FFBF00', text: 'Low quality article' },
  { color: '#6c757d', text: 'Unknown' },
];
