import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePersonDetails } from '../requests';
import { UpdatePersonDetailsPayload } from '../../types';

const useUpdatePersonDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      personId,
      payload,
    }: {
      personId: string;
      payload: UpdatePersonDetailsPayload;
    }) => updatePersonDetails(personId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['person_details'] });
      queryClient.invalidateQueries({ queryKey: ['groups'] });
    },
  });
};

export default useUpdatePersonDetails;
