import { Person, PersonResult } from '../../../services/api/search/types';

export enum Field {
  FULL_NAME = 'full_name',
  REASONING = 'reasoning',
}

export const defaultRowsPerPage = 25;

export const filterPeopleByKeyword = (person: Person, keyword: string) => {
  return (
    person.full_name.toLowerCase().includes(keyword.toLowerCase()) ||
    person.reasoning.toLowerCase().includes(keyword.toLowerCase())
  );
};

export const exportFileName = 'trending_people';

export const exportHeaders = ['Name', 'Position'];

export const flattenPeopleExtracts = (people: PersonResult[]): Person[] => {
  return people.flatMap((item) => item.extracts);
};
