import qs from 'qs';

import apiClient from '../../apiClient';
import { BASE_API_URL } from '../../config';
import { FetchGroupsPayload, Group } from '../types';

export const fetchGroups = (
  payload: FetchGroupsPayload,
): Promise<{ data: Group[]; totalCount: string }> => {
  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([_, value]) => value != null),
  );

  return apiClient
    .get(`${BASE_API_URL}/groups`, {
      params: filteredPayload,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then((response) => ({
      data: response.data,
      totalCount: response.headers['x-total-count'],
    }));
};
